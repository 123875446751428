import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {EduFilterValue, EduTradingReduxState, PlaylistInfo} from "core/redux/eduTrading/EduTradingReduxState";
import EduTradingEngine from "core/engine/EduTradingEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {
    EduTradingFetchAcademyVideosType,
    EduTradingFetchArticlesType,
    EduTradingFetchEBooksType,
    EduTradingFetchEconomicCalendarEventsType,
    EduTradingFetchSignalsType,
    EduTradingSetAcademyVideos,
    EduTradingSetAcademyVideosPayload,
    EduTradingSetActiveChartTab,
    EduTradingSetActiveChartTabPayload,
    EduTradingSetActiveTradeContentTab,
    EduTradingSetActiveTradeContentTabPayload,
    EduTradingSetArticles,
    EduTradingSetArticlesPayload,
    EduTradingSetEBooks,
    EduTradingSetEBooksPayload,
    EduTradingSetEconomicCalendarEvents,
    EduTradingSetEconomicCalendarEventsPayload,
    EduTradingSetSignals,
    EduTradingSetSignalsPayload,
    EduTradingSetEconomicCalendarPeriod,
    EduTradingSetEconomicCalendarPeriodPayload,
    EduTradingSetEconomicCalendarFilters,
    EduTradingSetEconomicCalendarFiltersPayload,
    EduTradingSetEconomicCalendarDateFilter,
    EduTradingSetEconomicCalendarDateFilterPayload,
    EduTradingSetSignalsPeriod,
    EduTradingSetSignalsPeriodPayload,
    EduTradingSetAcademyPlaylistProgress,
    EduTradingSetAcademyPlaylistProgressPayload,
    EduTradingSetLoadingPayload,
    EduTradingSetLoading,
    EduTradingSetCryptoCalendarEvents,
    EduTradingSetCryptoCalendarEventsPayload,
    EduTradingFetchCryptoCalendarEventsType,
    EduTradingSetCryptoCalendarPeriod,
    EduTradingSetCryptoCalendarPeriodPayload,
    EduTradingSetCryptoCalendarDateFilter,
    EduTradingSetCryptoCalendarDateFilterPayload,
    EduTradingSetCryptoCalendarFilters,
    EduTradingFiltersPayload,
    EduTradingSetSignalsFilters,
    EduTradingSetMarketSearchVideos,
    EduTradingSetMarketSearchVideosPayload,
    EduTradingFetchMarketVideosType,
    EduTradingFetchCurrencyStrengthMeterType,
    EduTradingSetCurrencyStrengthMeter,
    EduTradingSetCurrencyStrengthMeterPayload,
    EduTradingFetchGlossaryType,
    EduTradingSetGlossary,
    EduTradingSetGlossaryPayload,
    EduTradingFetchTrendAnalysisType,
    EduTradingSetTrendAnalisys,
    EduTradingSetTrendAnalisysPayload,
    EduTradingSetActiveNewsTab,
    EduTradingSetActiveNewsTabPayload,
} from "core/redux/eduTrading/EduTradingReduxActions";
import {EduTradingTradeContentTab} from "enum/EduTradingTradeContentTab";
import {EduTradingChartTab} from "enum/EduTradingChartTab";
import {TSMap} from "typescript-map";
import {EduTradingEconomicCalendarPeriod} from "protocol/edutrading/EduTradingEconomicCalendarPeriod";
import {EduTradingSignalsPeriod} from "protocol/edutrading/EduTradingSignalsPeriod";
import {
    SetFollowedAccount, SetFollowedAccountPayload,
    SetFollowedAccounts,
    SetFollowedAccountsPayload
} from "core/redux/partner/PartnerReduxActions";
import {EduTradingCryptoCalendarPeriod} from "protocol/edutrading/EduTradingCryptoCalendarPeriod";
import {EduTradingMarketVideoType} from "protocol/edutrading/EduTradingMarketVideoType";
import {EduTradingMarketVideo} from "protocol/edutrading/EduTradingMarketVideosResponse";
import Utils from "platform/util/Utils";
import {EduTradingNewsCategoryType} from "protocol/edutrading/EduTradingNewsCategoryType";
import {EduTradingArticle} from "protocol/edutrading/EduTradingArticlesResponse";

const initialState = (): EduTradingReduxState => {
    return {
        Brokers: [
            {id: 41, name: "Fortissio", logo: "Fortissio", rating: 4.7},
            {id: 41, name: "Inviertas", logo: "Inviertas", rating: 4.9},
            {id: 41, name: "Warren Bowie and Smith", logo: "WarrenBowieAndSmith", rating: 4.2},
            {id: 41, name: "XLNTrade", logo: "XLNTrade", rating: 4.5},
            {id: 41, name: "Tradit", logo: "Tradit", rating: 4.7},
        ],
        ActiveChartTab: EduTradingChartTab.Chart,
        ActiveTradeContentTab: EduTradingTradeContentTab.Brokers,
        AcademyVideos: [],
        EBooks: [],
        Articles: new TSMap<EduTradingNewsCategoryType, EduTradingArticle[]>(),
        Events: [],
        Signals: [],
        economicCalendarPeriod: EduTradingEconomicCalendarPeriod.TodayUpcoming,
        economicCalendarFilters: new TSMap<string, EduFilterValue[]>(),
        economicCalendarDateFilter: null,
        signalsPeriod: EduTradingSignalsPeriod.All,
        signalsFilters: new TSMap<string, EduFilterValue[]>(),
        AcademyPlaylistProgress: new TSMap<string, PlaylistInfo>(),
        cryptoEvents: [],
        cryptoCalendarPeriod: EduTradingCryptoCalendarPeriod.ThisWeek,
        cryptoCalendarFilters: new TSMap<string, EduFilterValue[]>(),
        cryptoCalendarDateFilter: null,
        marketVideos: new TSMap<EduTradingMarketVideoType, EduTradingMarketVideo[]>(),
        currencyStrength: [],
        glossary: [],
        trendAnalysis: [],
        NewsActiveTab: new TSMap<EduTradingNewsCategoryType, string>(),
    }
}

export default class EduTradingReducer extends Reducer<EduTradingReduxState> {

    private static _instance: EduTradingReducer;

    public static instance(): EduTradingReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const engine: EduTradingEngine = Platform.engine(ServiceType.EduTrading);
        this._middlewareActions.set(EduTradingFetchAcademyVideosType, engine.DoGetAcademyVideos);
        this._middlewareActions.set(EduTradingFetchEBooksType, engine.DoGetEBooks);
        this._middlewareActions.set(EduTradingFetchArticlesType, engine.DoGetArticles);
        this._middlewareActions.set(EduTradingFetchEconomicCalendarEventsType, engine.DoGetEconomicCalendarEvents);
        this._middlewareActions.set(EduTradingFetchSignalsType, engine.DoGetSignals);
        this._middlewareActions.set(EduTradingFetchCryptoCalendarEventsType, engine.DoGetCryptoCalendarEvents);
        this._middlewareActions.set(EduTradingFetchMarketVideosType, engine.DoGetMarketVideos);
        this._middlewareActions.set(EduTradingFetchCurrencyStrengthMeterType, engine.DoGetCurrencyStrengthMeter);
        this._middlewareActions.set(EduTradingFetchGlossaryType, engine.DoGetGlossary);
        this._middlewareActions.set(EduTradingFetchTrendAnalysisType, engine.DoGetTrendAnalysis);
    }

    public get name(): string {
        return "eduTrading";
    }

    protected setup(builder: ReducerBuilder<EduTradingReduxState>): void {
        builder
            .init(initialState())
            .handle(SetFollowedAccounts, (state: EduTradingReduxState, {payload}: Action<SetFollowedAccountsPayload>) => {
                if (payload.Accounts.size() > 0) {
                    return Object.assign({}, state, {
                        ActiveTradeContentTab: EduTradingTradeContentTab.Trade,
                    });
                }
                return state;
            })
            .handle(SetFollowedAccount, (state: EduTradingReduxState, {}: Action<SetFollowedAccountPayload>) => {
                return Object.assign({}, state, {
                    ActiveTradeContentTab: EduTradingTradeContentTab.Trade,
                });
            })
            .handle(EduTradingSetActiveChartTab, (state: EduTradingReduxState, {payload}: Action<EduTradingSetActiveChartTabPayload>) => {
                return Object.assign({}, state, {
                    ActiveChartTab: payload.Tab,
                });
            })
            .handle(EduTradingSetActiveTradeContentTab, (state: EduTradingReduxState, {payload}: Action<EduTradingSetActiveTradeContentTabPayload>) => {
                return Object.assign({}, state, {
                    ActiveTradeContentTab: payload.Tab,
                });
            })
            .handle(EduTradingSetAcademyVideos, (state: EduTradingReduxState, {payload}: Action<EduTradingSetAcademyVideosPayload>) => {
                return Object.assign({}, state, {
                    AcademyVideos: payload.AcademyVideos,
                });
            })
            .handle(EduTradingSetEBooks, (state: EduTradingReduxState, {payload}: Action<EduTradingSetEBooksPayload>) => {
                return Object.assign({}, state, {
                    EBooks: payload.Books,
                });
            })
            .handle(EduTradingSetArticles, (state: EduTradingReduxState, {payload}: Action<EduTradingSetArticlesPayload>) => {
                const newState: EduTradingReduxState = Utils.merge({}, state);
                newState.Articles.set(payload.articleType, payload.Articles);
                return newState;
            })
            .handle(EduTradingSetEconomicCalendarEvents, (state: EduTradingReduxState, {payload}: Action<EduTradingSetEconomicCalendarEventsPayload>) => {
                return Object.assign({}, state, {
                    Events: payload.Events,
                });
            })
            .handle(EduTradingSetSignals, (state: EduTradingReduxState, {payload}: Action<EduTradingSetSignalsPayload>) => {
                return Object.assign({}, state, {
                    Signals: payload.Signals,
                });
            })
            .handle(EduTradingSetEconomicCalendarPeriod, (state: EduTradingReduxState, {payload}: Action<EduTradingSetEconomicCalendarPeriodPayload>) => {
                return Object.assign({}, state, {
                    economicCalendarPeriod: payload.period,
                });
            })
            .handle(EduTradingSetEconomicCalendarFilters, (state: EduTradingReduxState, {payload}: Action<EduTradingSetEconomicCalendarFiltersPayload>) => {
                return Object.assign({}, state, {
                    economicCalendarFilters: payload.filters,
                });
            })
            .handle(EduTradingSetEconomicCalendarDateFilter, (state: EduTradingReduxState, {payload}: Action<EduTradingSetEconomicCalendarDateFilterPayload>) => {
                return Object.assign({}, state, {
                    economicCalendarDateFilter: payload.dateFilter,
                });
            })
            .handle(EduTradingSetSignalsPeriod, (state: EduTradingReduxState, {payload}: Action<EduTradingSetSignalsPeriodPayload>) => {
                return Object.assign({}, state, {
                    signalsPeriod: payload.period,
                });
            })
            .handle(EduTradingSetAcademyPlaylistProgress, (state: EduTradingReduxState, {payload}: Action<EduTradingSetAcademyPlaylistProgressPayload>) => {
                return Object.assign({}, state, {
                    AcademyPlaylistProgress: payload.data,
                });
            })
            .handle(EduTradingSetLoading, (state: EduTradingReduxState, {payload}: Action<EduTradingSetLoadingPayload>) => {
                return Object.assign({}, state, {
                    loading: payload.loading,
                });
            })
            .handle(EduTradingSetCryptoCalendarEvents, (state: EduTradingReduxState, {payload}: Action<EduTradingSetCryptoCalendarEventsPayload>) => {
                return Object.assign({}, state, {
                    cryptoEvents: payload.cryptoEvents,
                });
            })
            .handle(EduTradingSetCryptoCalendarPeriod, (state: EduTradingReduxState, {payload}: Action<EduTradingSetCryptoCalendarPeriodPayload>) => {
                return Object.assign({}, state, {
                    cryptoCalendarPeriod: payload.period,
                });
            })
            .handle(EduTradingSetCryptoCalendarDateFilter, (state: EduTradingReduxState, {payload}: Action<EduTradingSetCryptoCalendarDateFilterPayload>) => {
                return Object.assign({}, state, {
                    cryptoCalendarDateFilter: payload.dateFilter,
                });
            })
            .handle(EduTradingSetCryptoCalendarFilters, (state: EduTradingReduxState, {payload}: Action<EduTradingFiltersPayload>) => {
                return Object.assign({}, state, {
                    cryptoCalendarFilters: payload.filters,
                });
            })
            .handle(EduTradingSetSignalsFilters, (state: EduTradingReduxState, {payload}: Action<EduTradingFiltersPayload>) => {
                return Object.assign({}, state, {
                    signalsFilters: payload.filters,
                });
            })
            .handle(EduTradingSetMarketSearchVideos, (state: EduTradingReduxState, {payload}: Action<EduTradingSetMarketSearchVideosPayload>) => {
                const newState: EduTradingReduxState = Utils.merge({}, state);
                newState.marketVideos.set(payload.videoType, payload.videos);
                return newState;
            })
            .handle(EduTradingSetCurrencyStrengthMeter, (state: EduTradingReduxState, {payload}: Action<EduTradingSetCurrencyStrengthMeterPayload>) => {
                return Object.assign({}, state, {
                    currencyStrength: payload.currencies,
                });
            })
            .handle(EduTradingSetGlossary, (state: EduTradingReduxState, {payload}: Action<EduTradingSetGlossaryPayload>) => {
                return Object.assign({}, state, {
                    glossary: payload.glossary,
                });
            })
            .handle(EduTradingSetTrendAnalisys, (state: EduTradingReduxState, {payload}: Action<EduTradingSetTrendAnalisysPayload>) => {
                return Object.assign({}, state, {
                    trendAnalysis: payload.trendAnalysis,
                });
            })
            .handle(EduTradingSetActiveNewsTab, (state: EduTradingReduxState, {payload}: Action<EduTradingSetActiveNewsTabPayload>) => {
                const newState: EduTradingReduxState = Utils.merge({}, state);
                newState.NewsActiveTab.set(payload.categoryType, payload.tab);
                return newState;
            });
    }
}

