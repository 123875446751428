import React, {useState, useContext, useEffect, useCallback} from 'react'
import {Tour} from './Tour'
import {ProviderProps, StepType, TourProps} from "./types";

const defaultState = {
  isOpen: false,
  setIsOpen: () => false,
  currentStep: 0,
  setCurrentStep: () => 0,
  steps: [],
  setSteps: () => [],
  setMeta: () => '',
  disabledActions: false,
  setDisabledActions: () => false,
  components: {},
  setCurrentStepById: ()=>{},
}

const TourContext = React.createContext<TourProps>(defaultState)

const TourProvider: React.FC<ProviderProps> = ({
  children,
  defaultOpen = false,
  startAt = 0,
  steps: defaultSteps,
  setCurrentStep: customSetCurrentStep,
  currentStep: customCurrentStep,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [currentStep, setCurrentStep] = useState(startAt)
  const [steps, setSteps] = useState(defaultSteps)
  const [meta, setMeta] = useState('')
  const [disabledActions, setDisabledActions] = useState(false)
  const [currentStepId, setCurrentStepId] =useState<string>();

  useEffect(()=>{
    const step = steps[currentStep];
    setCurrentStepId(step?.id);
  },[steps, currentStep]);

  const setCurrentStepById = useCallback((id: string) => {
    steps.forEach((step: StepType, i) => {
      if (step.id === id) {
        setCurrentStep(i);
      }
    });
  },[steps]);

  const goNextStep = useCallback(()=>{
    setCurrentStep(currentStep + 1);
  },[steps, currentStep])

  const value = {
    isOpen,
    setIsOpen,
    currentStep: customCurrentStep || currentStep,
    setCurrentStep:
      customSetCurrentStep && typeof customSetCurrentStep === 'function'
        ? customSetCurrentStep
        : setCurrentStep,
    steps,
    setSteps,
    disabledActions,
    setDisabledActions,
    meta,
    setMeta,
    currentStepId,
    setCurrentStepById,
    goNextStep,
    ...props,
  }

  return (
    <TourContext.Provider value={value}>
      {children}
      {isOpen ? <Tour {...value} /> : null}
    </TourContext.Provider>
  )
}

export { TourContext, TourProvider }

export function useTour() {
  return useContext(TourContext)
}
