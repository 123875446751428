import AsyncComponent from "platform/component/async/AsyncComponent";

export const TradingAsync = AsyncComponent(() => {
    return import("../component/root/TradingRoot");
});

export const TradingMobileAsync = AsyncComponent(() => {
    return import("../component/root/TradingRootMob");
});

export const EduTradingAsync = AsyncComponent(() => {
    return import("../component/edutrading/EduTradingRoot");
});

export const EduTradingMobileAsync = AsyncComponent(() => {
    return import("../component/edutrading/EduTradingRootMob");
});
