import {PortfolioType} from "enum/PortfolioType";
import {TSMap} from "typescript-map";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {AccountLog} from "protocol/account/AccountLog";

interface PortfolioReduxState {

    activePortfolioTab: PortfolioType;
    counters: TSMap<PortfolioType, PortfolioCounter>;
    tables?: TSMap<PortfolioType, PortfolioTableState>;
    accountLogs?: AccountLog[];
    accountLogsPending?: boolean;
}

interface PortfolioCounter {

    count?: number;
    filterCount?: number;
}

interface PortfolioTableState {

    activeColumn: TradeColumn;
    sort: TSMap<TradeColumn, SortDirection>;
}

const DefaultPortfolioTablesStates = (): TSMap<PortfolioType, PortfolioTableState> => {
    const tables: TSMap<PortfolioType, PortfolioTableState> = new TSMap();
    tables.set(PortfolioType.ClosedTrades, {activeColumn: TradeColumn.CloseDate, sort: new TSMap<TradeColumn, SortDirection>().set(TradeColumn.CloseDate, SortDirection.Desk)});
    tables.set(PortfolioType.OpenTrades, {activeColumn: TradeColumn.CreatedDate, sort: new TSMap<TradeColumn, SortDirection>().set(TradeColumn.CreatedDate, SortDirection.Desk)});
    tables.set(PortfolioType.EntryOrders, {activeColumn: TradeColumn.CreatedDate, sort: new TSMap<TradeColumn, SortDirection>().set(TradeColumn.CreatedDate, SortDirection.Desk)});
    tables.set(PortfolioType.OrdersHistory, {activeColumn: TradeColumn.CloseDate, sort: new TSMap<TradeColumn, SortDirection>().set(TradeColumn.CloseDate, SortDirection.Desk)});
    tables.set(PortfolioType.ActivityLog, {activeColumn: TradeColumn.Time, sort: new TSMap<TradeColumn, SortDirection>().set(TradeColumn.Time, SortDirection.Desk)});
    return tables;
}

export {
    PortfolioReduxState,
    PortfolioCounter,
    PortfolioTableState,
    DefaultPortfolioTablesStates
};
