import {Routes, RouteType} from 'core/router/Routes';
import {TradeColumn} from "enum/TradeColumn";
import Utils from "platform/util/Utils";
import React, {Dispatch, useEffect} from "react";
import Translations from "platform/translation/Translations";
import {PortfolioType} from "enum/PortfolioType";
import {SettingsTab} from "enum/SettingsTab";
import {TranslationKey} from "enum/TranslationKey";
import {BannerType} from "enum/BannerType";
import {WithdrawalPending} from "core/redux/account/WithdrawalReduxState";
import {DoGetURL, RemoveBanner, SetTradingModal} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {DoCancelWithdrawal} from "core/redux/account/WithdrawalReduxActions";
import {TradeType} from "protocol/trade/TradeType";
import CardType from "enum/CardType";
import {PositionPromotion} from "platform/protocol/trading/PositionPromotion";
import {PromotionType} from "platform/protocol/enum/PromotionType";
import {PromotionUtil} from "./PromotionUtil";
import {OverviewType} from 'enum/OverviewType';
import {Banner} from "core/redux/app/AppReduxState";
import Parameter from "platform/util/Parameter";
import {TranslationParam} from "enum/TranslationParam";
import {ChatNotification, MarginEvent, RebatePlanData} from "protocol/account/GetAccountStateResponse";
import {Currency} from "platform/enum/Currency";
import {NumberFormat} from "core/format/NumberFormat";
import Platform from "platform/Platform";
import {UrlType} from "platform/enum/UrlType";
import DateTimeFormat from 'core/format/DateTimeFormat';
import {BOChatSetVisible, BOChatStart} from "core/redux/inbox/BOChatReduxActions";
import {SymbolCategory} from "enum/SymbolCategory";
import {FormFieldType} from "enum/FormFieldType";
import {FilterDateType} from 'core/redux/filter/FilterReduxState';
import {SymbolAlert} from "protocol/alerts/SymbolAlert";
import {SymbolAlertType} from "enum/SymbolAlertType";
import {PositionDirection as PosDirection, PositionDirection} from "platform/protocol/enum/PositionDirection";
import {ChangePercentAlertType} from "enum/ChangePercentAlertType";
import HeaderType from 'enum/HeaderType';
import {BIEventReferrer} from 'enum/BIEventReferrer';
import {VerificationMethodType} from 'enum/VerificationMethodType';
import {TradeManagerTab} from "enum/TradeManagerTab";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {TradingAccountAction} from "enum/TradingAccountAction";
import {BIEventType} from "enum/BIEventType";
import {Deal} from "platform/protocol/trading/Deal";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";
import {OrderType} from "platform/protocol/trading/OrderType";
import {Configuration} from "core/configuration/Configuration";
import {StoreState} from "core/redux/StoreState";
import {EntryOrderCancellationReason} from "platform/protocol/trading/EntryOrderCancellationReason";
import {BrandProp, BrandProps} from "platform/redux/core/CoreReduxState";
import {OnProcessTopUpButton} from "core/redux/account/AccountReduxActions";
import moment from "moment-mini";
import {domToReact, HTMLReactParserOptions} from "html-react-parser";
import {SymbolsState} from "core/state/SymbolsState";
import {ServiceType} from "enum/ServiceType";
import {RolloverMeasuringType} from "enum/RolloverMeasuringType";
import {SymbolDetails} from "protocol/symbol/GetSymbolDetailsResponse";

const ALL_TABS: TradeManagerTab[] = [
    TradeManagerTab.Trade,
    TradeManagerTab.Positions,
    TradeManagerTab.Info,
    TradeManagerTab.Chart,
    TradeManagerTab.Promotions,
    TradeManagerTab.Signals,
    TradeManagerTab.Alerts
];

export class UiUtil {

    private constructor() {
    }

    public static IsTradeManagerSidebar = (state: StoreState, name?: string): boolean => {
        const {ClosedTradeDetail, OrderDetail} = state.closedTrades;
        return !state.app.openMenu && Utils.isNull(ClosedTradeDetail) && Utils.isNull(OrderDetail) && !Routes.IsPartnerRoute(name);
    }

    public static tradeColumnTitle = (column: TradeColumn, portfolioTab?: PortfolioType, isMobile?: boolean, shortView?: boolean): string => {
        if (column === TradeColumn.Icon || column === TradeColumn.Trade || column === TradeColumn.Info) {
            return "";
        } else if (column === TradeColumn.Order) {
            return Translations.text(TranslationKey.units);
        } else if (portfolioTab === PortfolioType.EntryOrders || portfolioTab === PortfolioType.OrdersHistory) {
            if (column === TradeColumn.CreatedDate) {
                return Translations.text("trade.column.orderDate");
            } else if (column === TradeColumn.OpenRate) {
                return Translations.text("trade.column.orderRate");
            }
        } else if (column === TradeColumn.Price) {
            return Translations.text(TranslationKey.change);
        } else if (column === TradeColumn.OpenRate && shortView) {
            return Translations.text(TranslationKey.tradeColumnTotalProfit);
        } else if (column === TradeColumn.TotalProfit && shortView ) {
            return Translations.text(TranslationKey.units);
        } else if (column === TradeColumn.Chart1d) {
            return `${Translations.text(TranslationKey.tradeColumnChart1d)} ${Translations.text(TranslationKey.low)}/${Translations.text(TranslationKey.high)}`;
        } else if (column === TradeColumn.PatternTypes) {
            if (isMobile) {
                return Translations.text(TranslationKey.pattern);
            } else {
                return Translations.text(TranslationKey.patternTypes);
            }
        }
        return Translations.text("trade.column." + column);
    }

    public static verificationMethodName = (methodType: VerificationMethodType): string => {
        if (methodType) {
            switch (methodType) {
                case VerificationMethodType.SMS:
                    return "sms";
                case VerificationMethodType.Email:
                    return "email";
                case VerificationMethodType.Call:
                    return "phoneCall";
            }
        }
        return "";
    }

    public static verificationMethodTitles = (methodType: VerificationMethodType): TranslationKey[] => {
        if (Utils.isNotNull(methodType)) {
            switch (methodType) {
                case VerificationMethodType.Email:
                    return [TranslationKey.verificationEmail, TranslationKey.verificationCodeEmail];
                case VerificationMethodType.SMS:
                    return [TranslationKey.verificationSms, TranslationKey.verificationCodeSms];
            }
        }
        return null;
    };

    public static getWelcomingMessage = (): string => {
        const format = 'hh:mm a';
        const now = moment(DateTimeFormat.getTimeFormat(new Date()), format);

        const morningStart = moment("6:00 am", format);
        const morningEnd = moment("12:00 pm", format);
        const afternoon = moment("5:00 pm", format);
        const evening = moment("9:00 pm", format);

        if (now.isBetween(morningStart, morningEnd) || now.isSame(morningStart)) {
            return Translations.text(TranslationKey.goodMorning);
        }
        if (now.isBetween(morningEnd, afternoon) || now.isSame(morningEnd)) {
            return Translations.text(TranslationKey.goodAfternoon);
        }
        if (now.isBetween(afternoon, evening) || now.isSame(afternoon)) {
            return Translations.text(TranslationKey.goodEvening);
        }
        if (now.isSameOrAfter(evening)) {
            return Translations.text(TranslationKey.goodNight);
        }
        return null;
    }

    public static symbolCategoryTitle = (categoryName: string): string => {
        if (categoryName === SymbolCategory.All) {
            return Translations.text(TranslationKey.all);
        } else if (categoryName === SymbolCategory.MyWatchlist) {
            return Translations.text(TranslationKey.myWatchlist);
        }
        const symbolsState: SymbolsState = Platform.state<SymbolsState>(ServiceType.Symbols);
        return symbolsState.getCategoryLocalization(categoryName) || Translations.text(`symbol.category.${categoryName}`);
    }

    public static symbolDetailsRollOver = (symbolDetails: SymbolDetails, PositionDirection?: PosDirection): string => {
        if (symbolDetails?.RolloverMeasuringType) {
            const rollover: number = PositionDirection === PosDirection.Buy ? symbolDetails?.RolloverLong : symbolDetails?.RolloverShort;
            switch (symbolDetails?.RolloverMeasuringType) {
                case RolloverMeasuringType.Percentage:
                    return `${NumberFormat.formatNumber(rollover)}%`;
                case RolloverMeasuringType.Fixed:
                    return `$${NumberFormat.formatNumber(rollover)}`;
                case RolloverMeasuringType.Pips:
                    return `${NumberFormat.formatNumber(rollover)} pips`;
            }
        }
        return "";
    }

    public static portfolioTitle = (type: PortfolioType): string => {
        switch (type) {
            case PortfolioType.FinancialSummary:
                return Translations.text(TranslationKey.financialSummary);
            case PortfolioType.AvailableFeatures:
                return Translations.text(TranslationKey.availablePromotions);
            default:
                return Translations.text("myTrades." + type);
        }
    }

    public static settingsTabTitle = (settingTab: SettingsTab): string => {
        const key = () => {
            switch (settingTab) {
                case SettingsTab.Funds:
                    return TranslationKey.funds;
                case SettingsTab.Profile:
                    return TranslationKey.profile;
                case SettingsTab.FinancialSummary:
                    return TranslationKey.financialSummary;
                case SettingsTab.Legal:
                    return TranslationKey.legal
            }
        };
        return Translations.text(key());
    };

    public static TradeManagerTabs = (symbol: TradeSymbol): TradeManagerTab[] => {
        if (symbol) {
            const tabs: TradeManagerTab[] = [];
            const config: Configuration = Platform.config<Configuration>();
            const signalsHidden: boolean = (config.blockedMenuRoutes || []).indexOf(RouteType.Signals) > -1;
            ALL_TABS.forEach((tab: TradeManagerTab) => {
                const TabHidden: boolean = (tab === TradeManagerTab.Chart && config.chartIQHidden) || (tab === TradeManagerTab.Signals && signalsHidden);
                if (!TabHidden) {
                    tabs.push(tab);
                }
            });
            return tabs;
        }
        return [TradeManagerTab.Trade, TradeManagerTab.Positions, TradeManagerTab.Promotions];
    }

    public static isClickOutside = (event, refs: Array<React.RefObject<HTMLDivElement>>): boolean => {
        const target = event?.target as HTMLDivElement;
        let outside: boolean = true;
        for (let i = 0; i < refs.length; i++) {
            const ref: React.RefObject<HTMLDivElement> = refs[i];
            const {clientX, clientY} = event;
            if (ref.current) {
                const {top, left, right, bottom} = ref.current.getBoundingClientRect();
                if ((clientX >= left && clientX <= right && clientY >= top && clientY <= bottom)
                    || target.closest('div[class*=trading-modal]')
                    || target.closest('div[class*=trading-table-row]')
                    || target.closest('div[class*=popup-wrapper]')
                ) {
                    outside = false;
                    break;
                }
            }
        }
        return outside;
    }

    public static BannerText = (banner: Banner<any>): string => {
        const bannerType: BannerType = banner?.type;
        let key: TranslationKey;
        if (Utils.isNotNull(bannerType)) {
            switch (bannerType) {
                case BannerType.BOChat:
                    key = TranslationKey.chatInitManager;
                    break;
                case BannerType.BOChatReopen:
                    key = TranslationKey.bannerBoChatReopenNewMessages;
                    break;
                case BannerType.FinalizeRegistration:
                case BannerType.Deposit:
                    key = TranslationKey.bannersAccountReadyToStart;
                    break;
                case BannerType.VerifyIdentity:
                    key = TranslationKey.bannersAccountLastStep;
                    break;
                case BannerType.AccountVerification:
                    key = TranslationKey.bannersAccountAccountPendingApproval;
                    break;
                case BannerType.ApplyForProfessional:
                    key = TranslationKey.bannersBecomeAProfessionalInfo;
                    break;
                case BannerType.InAppropriate:
                    key = TranslationKey.bannersBecomeAProfessionalDidNotMeetCriteria;
                    break;
                case BannerType.WithdrawalCancel:
                    key = TranslationKey.bannersWithdrawalProcessingWithdrawal;
                    break;
                case BannerType.UploadDocuments:
                    key = TranslationKey.bannersWithdrawalDocumentsAreRequired;
                    break;
                case BannerType.PendingWithdrawal:
                    key = TranslationKey.bannersWithdrawalSuccessInfo;
                    break;
                case BannerType.MarginCall:
                    key = TranslationKey.bannersMarginCallInfo;
                    break;
                case BannerType.Liquidation:
                    key = TranslationKey.bannersLiquidationInfo;
                    break;
                case BannerType.SignDeclaration:
                    key = TranslationKey.bannerSignDeclarationInfo;
                    break;
                case BannerType.QuantumAccount:
                    key = TranslationKey.quantumAccountExpire;
                    break;
            }
        }
        if (key) {
            const params: Parameter[] = [];
            if (bannerType === BannerType.MarginCall) {
                const marginEvent: MarginEvent = banner.payload;
                params.push(Parameter.Of(TranslationParam.amount, NumberFormat.formatPercent(marginEvent?.ConfiguredMarginPercentage)));
            } else if (bannerType === BannerType.PendingWithdrawal || bannerType === BannerType.WithdrawalCancel) {
                const withdrawal: WithdrawalPending = banner.payload;
                params.push(Parameter.Of(TranslationParam.value, `${withdrawal?.Amount < 0 ? "-" : ""}${Currency.sign(withdrawal?.PaymentAssetName)}${Math.abs(withdrawal?.Amount)}`));
            }
            return Translations.Text(key, params);
        }
        return null;
    }

    public static showBanner = (banner: BannerType, routeName: string): boolean => {
        if (Utils.isNotNull(banner)) {
            // TODO In future if we will have Marketing banners, then display them at routes
            // return routeName === RouteType.AccountOverview || routeName === RouteType.Symbols || routeName === RouteType.MyTrades || routeName === RouteType.AccountSettings;
            return true;
        }
        return false;
    }

    public static BannerTitle = (banner: BannerType): TranslationKey => {
        if (Utils.isNotNull(banner)) {
            switch (banner) {
                case BannerType.FinalizeRegistration:
                case BannerType.Deposit:
                case BannerType.VerifyIdentity:
                case BannerType.AccountVerification:
                case BannerType.InAppropriate:
                case BannerType.WithdrawalCancel:
                case BannerType.UploadDocuments:
                case BannerType.MarginCall:
                case BannerType.SignDeclaration:
                    return null;
                case BannerType.ApplyForProfessional:
                    return TranslationKey.bannersBecomeAProfessionalTitle;
                case BannerType.PendingWithdrawal:
                    return TranslationKey.bannersWithdrawalSuccessfullyProcessed;
                case BannerType.Liquidation:
                    return TranslationKey.bannersLiquidityTitle;
            }
        }
        return null;
    };

    public static BannerButtonTitle = (banner: BannerType): TranslationKey => {
        if (Utils.isNotNull(banner)) {
            switch (banner) {
                case BannerType.BOChat:
                    return TranslationKey.chatStart;
                case BannerType.BOChatReopen:
                    return TranslationKey.bannerBoChatReopenBtn;
                case BannerType.FinalizeRegistration:
                    return TranslationKey.bannersAccountCompleteYourAccount;
                case BannerType.VerifyIdentity:
                    return TranslationKey.bannersAccountVerifyYourIdentity;
                case BannerType.Deposit:
                case BannerType.MarginCall:
                case BannerType.Liquidation:
                    return TranslationKey.bannersAccountTopUpAccount;
                case BannerType.AccountVerification:
                    return null;
                case BannerType.ApplyForProfessional:
                    return TranslationKey.bannersBecomeAProfessionalBecomeAProfessional;
                case BannerType.InAppropriate:
                    return TranslationKey.bannersBecomeAProfessionalLearnMore;
                case BannerType.WithdrawalCancel:
                    return TranslationKey.bannersWithdrawalCancelRequest;
                case BannerType.UploadDocuments:
                    return TranslationKey.bannersWithdrawalUploadDocuments;
                case BannerType.PendingWithdrawal:
                    return TranslationKey.bannersWithdrawalViewDetails;
                case BannerType.SignDeclaration:
                    return TranslationKey.bannerSignDeclarationBtn;
            }
        }
        return null;
    }

    public static BannerColor = (banner: BannerType): string => {
        if (Utils.isNotNull(banner)) {
            switch (banner) {
                case BannerType.FinalizeRegistration:
                case BannerType.Deposit:
                case BannerType.VerifyIdentity:
                case BannerType.InAppropriate:
                case BannerType.UploadDocuments:
                case BannerType.MarginCall:
                case BannerType.Liquidation:
                    return "red";
                case BannerType.AccountVerification:
                case BannerType.WithdrawalCancel:
                case BannerType.BOChatReopen:
                case BannerType.BOChat:
                case BannerType.SignDeclaration:
                    return "yellow";
                case BannerType.ApplyForProfessional:
                    return "brand";
                case BannerType.PendingWithdrawal:
                    return "green";
                case BannerType.QuantumAccount:
                    return "green-gradient";
            }
        }
        return null;
    }

    public static BannerButtonColor = (banner: BannerType): string => {
        if (Utils.isNotNull(banner)) {
            switch (banner) {
                case BannerType.FinalizeRegistration:
                case BannerType.Deposit:
                case BannerType.VerifyIdentity:
                case BannerType.AccountVerification:
                case BannerType.InAppropriate:
                case BannerType.UploadDocuments:
                case BannerType.MarginCall:
                case BannerType.Liquidation:
                    return "";
                case BannerType.ApplyForProfessional:
                    return "brand";
                case BannerType.BOChatReopen:
                case BannerType.BOChat:
                case BannerType.WithdrawalCancel:
                case BannerType.SignDeclaration:
                    return "yellow";
                case BannerType.PendingWithdrawal:
                    return "green";
            }
        }
        return null;
    }

    public static BannerContainerClass = (banner: BannerType): string => {
        if (Utils.isNotNull(banner)) {
            switch (banner) {
                case BannerType.FinalizeRegistration:
                case BannerType.Deposit:
                case BannerType.VerifyIdentity:
                case BannerType.AccountVerification:
                case BannerType.InAppropriate:
                case BannerType.WithdrawalCancel:
                case BannerType.UploadDocuments:
                case BannerType.MarginCall:
                case BannerType.Liquidation:
                case BannerType.SignDeclaration:
                    return "";
                case BannerType.ApplyForProfessional:
                case BannerType.PendingWithdrawal:
                    return "form";
            }
        }
        return null;
    }

    public static BannerShowClose = (banner: BannerType): boolean => {
        if (Utils.isNotNull(banner)) {
            switch (banner) {
                case BannerType.InAppropriate:
                case BannerType.Deposit:
                case BannerType.FinalizeRegistration:
                case BannerType.VerifyIdentity:
                case BannerType.UploadDocuments:
                case BannerType.AccountVerification:
                case BannerType.BOChatReopen:
                case BannerType.BOChat:
                case BannerType.QuantumAccount:
                    return false;
            }
        }
        return true;
    }

    public static BannerClosePosition = (banner: BannerType): string => {
        if (Utils.isNotNull(banner)) {
            switch (banner) {
                case BannerType.FinalizeRegistration:
                case BannerType.Deposit:
                case BannerType.VerifyIdentity:
                case BannerType.AccountVerification:
                case BannerType.ApplyForProfessional:
                case BannerType.UploadDocuments:
                case BannerType.PendingWithdrawal:
                case BannerType.MarginCall:
                    return '';
                case BannerType.InAppropriate:
                case BannerType.WithdrawalCancel:
                case BannerType.Liquidation:
                case BannerType.SignDeclaration:
                    return 'center';
            }
        }
        return null;
    }

    public static BannerOptIn = (banner: Banner<any>, withdrawal: WithdrawalPending, dispatch: Dispatch<any>): void => {
        if (Utils.isNotNull(banner)) {
            switch (banner.type) {
                case BannerType.BOChat:
                    const chatNotification: ChatNotification = banner.payload;
                    dispatch(RemoveBanner({banner, block: false}));
                    dispatch(BOChatStart({token: chatNotification.Token}));
                    break;
                case BannerType.BOChatReopen:
                    dispatch(RemoveBanner({banner, block: false}));
                    dispatch(BOChatSetVisible({visible: true}));
                    break;
                case BannerType.FinalizeRegistration:
                    dispatch(DoGetURL({
                        urlType: UrlType.Kyc,
                        parameters: [Parameter.Of("win", Platform.environment().openWindow("empty"))]
                    }));
                    break;
                case BannerType.ApplyForProfessional:
                    dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.Professional,
                        info: {visible: true}
                    }));
                    break;
                case BannerType.VerifyIdentity:
                case BannerType.UploadDocuments:
                    dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.Documents,
                        info: {visible: true}
                    }));
                    break;
                case BannerType.Deposit:
                case BannerType.MarginCall:
                case BannerType.Liquidation:
                    dispatch(OnProcessTopUpButton({referrer: BIEventReferrer.Organic}));
                    break;
                case BannerType.InAppropriate:
                    dispatch(DoGetURL({
                        urlType: UrlType.Kyc,
                        parameters: [Parameter.Of("win", Platform.environment().openWindow("empty"))]
                    }));
                    break;
                case BannerType.WithdrawalCancel:
                    Platform.bi().track(BIEventType.BannerInteraction, {
                        ButtonName: "Cancel withdrawal"
                    });
                    dispatch(DoCancelWithdrawal({
                        withdrawal
                    }));
                    break;
                case BannerType.SignDeclaration:
                    dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.SignDeclaration,
                        info: {visible: true}
                    }));
                    break;
                case BannerType.PendingWithdrawal:
                    // TODO Display withdrawal details
                    break;
            }
        }
    };

    public static PortfolioFilterClass = (activePortfolioTab: PortfolioType, type: FilterDateType, closedTradesDefaultFilter?: FilterDateType, closedTradesPending?: boolean): string => {
        switch(activePortfolioTab) {
            case PortfolioType.OpenTrades:
                return type !== FilterDateType.All ? "active" : "";
            case PortfolioType.EntryOrders:
                return type !== FilterDateType.All ? "active" : "";
            case PortfolioType.ClosedTrades:
                return !closedTradesPending && type !== closedTradesDefaultFilter ? "active" : "";
            case PortfolioType.ActivityLog:
                return type !== FilterDateType.Today ? "active" : "";
            case PortfolioType.OrdersHistory:
                return type !== FilterDateType.ThisWeek ? "active" : "";
        }
        return null;
    }

    public static CloseTradeTitle = (tradeType: TradeType, massClose: boolean): TranslationKey => {
        if (tradeType === TradeType.MarketOrder) {
            return massClose ? TranslationKey.closeTrades : TranslationKey.closeTrade;
        } else {
            return massClose ? TranslationKey.deleteOrders : TranslationKey.deleteOrder;
        }
    };

    public static CloseTradeSubtitle = (tradeType: TradeType, massClose: boolean): TranslationKey => {
        if (tradeType === TradeType.MarketOrder) {
            return massClose ? TranslationKey.wantToCloseTrades : TranslationKey.wantToCloseTrade;
        } else {
            return massClose ? TranslationKey.wantToDeleteOrders : TranslationKey.wantToDeleteOrder;
        }
    };

    public static CloseTradesActionTitle = (tradeType: TradeType, massClose: boolean): TranslationKey => {
        if (tradeType === TradeType.MarketOrder) {
            return massClose ? TranslationKey.closeTradesUpper : TranslationKey.closeTradeUpper;
        } else {
            return massClose ? TranslationKey.deleteOrdersUpper : TranslationKey.deleteOrderUpper;
        }
    };

    public static InfoCardTitle = (type: CardType): TranslationKey => {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case CardType.Withdrawals:
                    return TranslationKey.settingsWithdrawalsTitle;
                case CardType.TopUpLimits:
                    return TranslationKey.settingsTopUpTitle;
                case CardType.CloseOutMethod:
                    return TranslationKey.settingsCloseOutTitle;
                case CardType.AnnualStatement:
                    return TranslationKey.settingsAnnualAnnualStatement;
                case CardType.MyLeverage:
                    return TranslationKey.settingsMyLeverageTitle;
                case CardType.EMT:
                    return TranslationKey.EMT;
            }
        }
        return null;
    };

    public static InfoCardText = (type: CardType, brandProps: BrandProps, EMTPercentage: number, htmlToReact: (html: string, options?: HTMLReactParserOptions) => ReturnType<typeof domToReact>): string => {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case CardType.Withdrawals:
                    const rule: BrandProp = brandProps?.keys?.WithdrawalProcessRule;
                    const parseResult =  rule && htmlToReact(rule.value);
                    return rule ? typeof parseResult === "object" ? parseResult[0] : parseResult : "";
                case CardType.TopUpLimits:
                    return Translations.text(TranslationKey.settingsTopUpInfo);
                case CardType.CloseOutMethod:
                    return Translations.text(TranslationKey.settingsCloseOutInfo);
                case CardType.AnnualStatement:
                    return Translations.text(TranslationKey.settingsAnnualInfo);
                case CardType.EMT:
                    const EMTEditProp: BrandProp = brandProps?.keys?.ExposureManagementToolEdit;
                    const EMTDisabledProp: BrandProp = brandProps?.keys?.ExposureManagementToolDisabled;
                    const EMTProp: BrandProp = Utils.greaterThen0(EMTPercentage) ? EMTEditProp : EMTDisabledProp;
                    return Utils.nullToEmpty(EMTProp?.value);
            }
        }
        return "";
    };

    public static InfoCardButtonTitle = (type: CardType, EMTPercentage: number): TranslationKey => {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case CardType.Withdrawals:
                    return TranslationKey.settingsWithdrawalsMakeWithdrawal;
                case CardType.TopUpLimits:
                    return TranslationKey.settingsTopUpManageLimits;
                case CardType.CloseOutMethod:
                    return TranslationKey.settingsCloseOutChangeCloseOutMethod;
                case CardType.AnnualStatement:
                    return TranslationKey.settingsAnnualSendToEmail;
                case CardType.MyLeverage:
                    return TranslationKey.settingsMyLeverageChangeMyLeverage;
                case CardType.EMT:
                    return Utils.greaterThen0(EMTPercentage) ? TranslationKey.edit : TranslationKey.enable;
            }
        }
        return null;
    };

    public static promotionDescriptionParameter = (type: number, promotion: PositionPromotion) => {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case PromotionType.SPREAD_CASHBACK:
                case PromotionType.POSITION_INSURANCE:
                    return PromotionUtil.promotionPercentage(type, [promotion]) + "%";
            }
        }
        return null;
    };

    public static promotionSubtitleParameter = (type: PromotionType, promotion: PositionPromotion) => {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case PromotionType.PROTECTED:
                    return PromotionUtil.promotionCount(type, [promotion]);
                default:
                    return PromotionUtil.promotionPercentage(type, [promotion]);
            }
        }
        return null;
    };

    public static promotionRowIcon = (promotionType: PromotionType) => {
        if (promotionType) {
            switch (promotionType) {
                case PromotionType.PROTECTED:
                    return "protected_position";
                case PromotionType.SPREAD_CASHBACK:
                    return "spread_cashback";
                case PromotionType.POSITION_INSURANCE:
                    return "position_insurance";
                case PromotionType.ROLLOVER_DISCOUNT:
                    return "rollover_discount";
            }
        }
        return null;
    }

    public static promotionDataExpirationParameter = (promotion: PositionPromotion) => {
        return Utils.isNotNull(promotion.ExpirationDate) ? DateTimeFormat.getLocalDate(new Date(promotion.ExpirationDate)) : null;
    }

    public static linkTitle = (routeName: string, portfolioTab?: PortfolioType): TranslationKey => {
        if (Utils.isNotNull(routeName)) {
            switch (routeName) {
                case RouteType.Symbols:
                    return TranslationKey.addSymbol;
                case RouteType.MyTrades:
                    return portfolioTab === PortfolioType.EntryOrders ? TranslationKey.placeOrder : TranslationKey.createTrade;
                case RouteType.Alerts:
                    return TranslationKey.setAnAlert;
            }
        }
        return null;
    }

    public static emptyMessage = (type: OverviewType, route: string, portfolioTab?: PortfolioType, category?: string): TranslationKey => {
        if (route === RouteType.Alerts) {
            return TranslationKey.noActiveAlerts;
        } else if (route === RouteType.Signals) {
            return category === SymbolCategory.MyWatchlist ? TranslationKey.noResultsSignalsWatchList : TranslationKey.noResultsSignals;
        } else if (Utils.isNotNull(OverviewType) && Utils.isNotNull(route)) {
            switch (type) {
                case OverviewType.MyTrades:
                    return route === RouteType.AccountOverview ? TranslationKey.noActiveTrades
                        : portfolioTab === PortfolioType.ClosedTrades ? TranslationKey.closedTradesNoTrades
                            : portfolioTab === PortfolioType.OrdersHistory ? TranslationKey.noOrdersHistory
                                : portfolioTab === PortfolioType.ActivityLog ? TranslationKey.noResultsActivityLogs
                                    : TranslationKey.myTradesNoActiveTrades;
                case OverviewType.MyWatchlist:
                    return route === RouteType.AccountOverview ? TranslationKey.noWatchSymbols : TranslationKey.symbolsNoActiveSymbols;
            }
        }
        return null;
    }

    public static RebatePlanMessage = (rebateData: RebatePlanData, currency: string): string => {
        if (rebateData) {
            if (rebateData.IsFinalLevel) {
                return Translations.text(TranslationKey.promotionModalRebatePlanInfoLastLevel);
            } else {
                return Translations.text(TranslationKey.promotionModalRebatePlanInfo,
                    Parameter.Of(TranslationParam.amount, NumberFormat.formatMoney(rebateData.VolumeGapFromNextLevelInRequestedAsset, currency)),
                    Parameter.Of(TranslationParam.value, NumberFormat.formatMoney(rebateData.NextLevelRebate, currency)));
            }
        }
        return null;
    }

    public static IsExternalProvidersRoute = (routeType: RouteType): boolean => {
        if (routeType) {
            switch (routeType) {
                case RouteType.News:
                case RouteType.EconomicCalendar:
                case RouteType.Signals:
                case RouteType.Lessons:
                case RouteType.Article:
                case RouteType.Videos:
                    return true;
            }
        }
        return false;
    }

    public static formError = (fieldType: FormFieldType): string => {
        const trKey: string = `form.field.error.${fieldType}`;
        if (Translations.hasKey(trKey)) {
            return Translations.text(trKey);
        }
        return Translations.text(TranslationKey.required);
    }

    public static alertTitle = (alert: SymbolAlert): string => {
        if (Utils.isNotNull(alert)) {
            switch(alert.SymbolAlertType) {
                case SymbolAlertType.Price:
                    return `${Translations.text(TranslationKey.price)} / ${Translations.text(PositionDirection[alert.Direction]?.toLowerCase())}`;
                case SymbolAlertType.ChangePercent:
                    return `${Translations.text(TranslationKey.change)} / ${Translations.text(ChangePercentAlertType[alert.ChangePercentAlertType])}`;
                case SymbolAlertType.Signal:
                    return `${Translations.text(TranslationKey.signals)}`;
            }
        }
        return null;
    }

    public static alertTabTitle = (type: SymbolAlertType): string => {
        if (type) {
            switch(type) {
                case SymbolAlertType.Price:
                    return Translations.text(TranslationKey.price);
                case SymbolAlertType.ChangePercent:
                    return Translations.text(TranslationKey.change);
                case SymbolAlertType.Signal:
                    return Translations.text(TranslationKey.signals);
            }
        }
        return null;
    }

    public static getHeaderType = (routeType: RouteType): HeaderType => {
        if (Utils.isNotNull(routeType)) {
            switch (routeType) {
                case RouteType.EconomicCalendar:
                case RouteType.News:
                case RouteType.Videos:
                case RouteType.Article:
                case RouteType.Alerts:
                case RouteType.Signals:
                case RouteType.Lessons:
                case RouteType.AccountSettings:
                case RouteType.FollowedAccounts:
                case RouteType.FollowedAccount:
                    return HeaderType.SubHeader;
            }
        }
        return HeaderType.MainHeader;
    }

    public static headerTitleKey = (routeType: RouteType): TranslationKey => {
        if (routeType) {
            switch (routeType) {
                case RouteType.Alerts:
                    return TranslationKey.menuAlerts;
                case RouteType.EconomicCalendar:
                    return TranslationKey.documentTitleEconomicCalendar;
                case RouteType.News:
                    return TranslationKey.menuNews;
                case RouteType.Videos:
                    return TranslationKey.lessonsVideos;
                case RouteType.Lessons:
                    return TranslationKey.titleLessons;
                case RouteType.Article:
                    return TranslationKey.menuNews;
                case RouteType.Signals:
                    return TranslationKey.signals;
                case RouteType.Tools:
                    return TranslationKey.tools;
                case RouteType.AccountSettings:
                    return TranslationKey.myAccount;
                case RouteType.FollowedAccounts:
                    return TranslationKey.followedAccounts;
                case RouteType.FollowedAccount:
                    return TranslationKey.titleFollowedAccounts;
            }
        }
        return null;
    }

    public static getAccountOptionTitle = (loginAccountAction: TradingAccountAction, switchToInvestEnabled?: boolean): TranslationKey => {
        if (Utils.isNotNull(loginAccountAction)) {
            switch (loginAccountAction) {
                case TradingAccountAction.SignUp:
                    return switchToInvestEnabled ? TranslationKey.investAccountSignUp : TranslationKey.quantumAccountSignUp;
                case TradingAccountAction.Switch:
                    return switchToInvestEnabled ? TranslationKey.investAccount : TranslationKey.quantumAccountSwitch;
            }
        }
        return null;
    }

    public static ShowTableColumn = (isInvestAccount: boolean, portfolioTab: PortfolioType, column: TradeColumn): boolean => {
        if (Utils.isNotNull(column)) {
            switch (column) {
                case TradeColumn.TakeProfit:
                case TradeColumn.StopLoss:
                    return !isInvestAccount;
                case TradeColumn.MarketValue:
                    return isInvestAccount || portfolioTab === PortfolioType.OpenTrades || portfolioTab === PortfolioType.ClosedTrades;
            }
        }
        return true;
    }

    public static showTableColumn = (isInvestAccount: boolean, title: string, index: number, portfolioTab?: PortfolioType): boolean => {
        if (Utils.isNotNull(title)) {
            switch (title) {
                case TranslationKey.tradeColumnMarketValue:
                    return isInvestAccount && portfolioTab !== PortfolioType.EntryOrders;
                case TranslationKey.tradeColumnTakeProfit:
                case TranslationKey.tradeColumnStopLoss:
                    return !isInvestAccount;
                case TranslationKey.tradeColumnCloseDate:
                    if (Utils.isNotNull(index)) {
                        if (index === 1) {
                            return isInvestAccount;
                        }
                        if (index === 2) {
                            return !isInvestAccount;
                        }
                    } else {
                        return true;
                    }
            }
        }
        return true;
    }

    public static OrderNotificationTitle = (deal: Deal): string => {
        if (deal) {
            if (deal.Status === EntryOrderStatus.Active || deal.Status === EntryOrderStatus.Pending) {
                return Translations.text(deal.Type === OrderType.Market ? TranslationKey.orderIsActiveMarket : TranslationKey.orderIsActiveEntryLimit);
            } else if (deal.Status === EntryOrderStatus.Inactive) {
                return Translations.text(TranslationKey.orderIsInActive);
            } else if (deal.Status === EntryOrderStatus.CancelRequest) {
                return Translations.text(TranslationKey.orderIsCancelling);
            }
        }
        return null;
    }

    public static OrderCancellationReasonTitle = (reason: EntryOrderCancellationReason): string => {
        let trKey: TranslationKey = TranslationKey.system;
        if (reason) {
            switch (reason) {
                case EntryOrderCancellationReason.Manual:
                    trKey = TranslationKey.backOffice;
                    break;
            }
        }
        return Translations.text(trKey);
    }

    public static isAlertOptionsValid (formik: any): boolean {

        if (formik.values[FormFieldType.NotifyByEmail] === true
            || formik.values[FormFieldType.NotifyByPushNotifications] === true) {
                return true;
        }
        return false;
    }

    public static hasPortfolioSettings = (portfolioType: PortfolioType): boolean => {
        if (Utils.isNotNull(portfolioType)) {
            switch (portfolioType) {
                case PortfolioType.OpenTrades:
                case PortfolioType.EntryOrders:
                case PortfolioType.ClosedTrades:
                case PortfolioType.OrdersHistory:
                case PortfolioType.ActivityLog:
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }

    public static PartnerPortfolioTabs = (): PortfolioType[] => {
        return [
            PortfolioType.OpenTrades,
            PortfolioType.EntryOrders,
            PortfolioType.ClosedTrades,
            PortfolioType.OrdersHistory,
            PortfolioType.ActivityLog,
            PortfolioType.FinancialSummary,
            PortfolioType.AvailableFeatures
        ];
    }
}

