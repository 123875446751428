export enum TranslationKey {

    AdvancedTechnicalAnalysis = "AdvancedTechnicalAnalysis",
    AlwaysShowChart = "AlwaysShowChart",
    BMMS = "BMMS",
    BigMovement = "BigMovement",
    Brokers = "Brokers",
    ConsecutiveCandles = "ConsecutiveCandles",
    CryptoTrading = "CryptoTrading",
    CryptocurrencyFundamentals = "CryptocurrencyFundamentals",
    DEMS = "DEMS",
    Daily = "Daily",
    EMT = "EMT",
    EMTDisabled = "EMT.disabled",
    EMTEnableTool = "EMT.enableTool",
    EMTEnabled = "EMT.enabled",
    EMTUpdated = "EMT.updated",
    ExpertTechnicalAnalysis = "ExpertTechnicalAnalysis",
    FinancialMarketIntroduction = "FinancialMarketIntroduction",
    FromNow = "FromNow",
    GMT = "GMT",
    H1 = "H1",
    Hourly = "Hourly",
    Insights = "Insights",
    Lessons = "Lessons",
    M15 = "M15",
    M5 = "M5",
    MetaTrader = "MetaTrader",
    NewTrading = "NewTrading",
    NextLesson = "NextLesson",
    NotifyBy = "NotifyBy",
    PPRMS = "PPRMS",
    PriceDirection = "PriceDirection",
    PriceType = "PriceType",
    Recurring = "Recurring",
    SetAlert = "SetAlert",
    TCMS = "TCMS",
    TechnicalAnalysisEssentials = "TechnicalAnalysisEssentials",
    VSMS = "VSMS",
    academyComplete = "academyComplete",
    accept = "accept",
    accessPushNotifications = "accessPushNotifications",
    accountDemo = "account.demo",
    accountReadyToUse = "accountReadyToUse",
    accountReal = "account.real",
    accountRegistrationData = "accountRegistrationData",
    accountStatements = "accountStatements",
    accountTiersHighestLevel = "accountTiersHighestLevel",
    accountTiersShareInfo = "accountTiers.shareInfo",
    accountTiersUnlockLevel = "accountTiers.unlockLevel",
    accountsTiers = "accountsTiers",
    actions = "actions",
    active = "active",
    actual = "actual",
    add = "add",
    addASymbol = "addASymbol",
    addAccount = "addAccount",
    addFollowedAccount = "addFollowedAccount",
    addLossCoverage = "addLossCoverage",
    addSpreadCashback = "addSpreadCashback",
    addSymbol = "addSymbol",
    addSymbols = "addSymbols",
    addToWatchlist = "addToWatchlist",
    added = "added",
    addedToWatchlist = "addedToWatchlist",
    addedToYourWatchlist = "addedToYourWatchlist",
    ago = "ago",
    agree = "agree",
    alert = "alert",
    alertChangeTypeDescriptionDaily = "alertChangeTypeDescriptionDaily",
    alertChangeTypeDescriptionFromNow = "alertChangeTypeDescriptionFromNow",
    alertPriceTypeDescription = "alertPriceTypeDescription",
    alertType = "alertType",
    all = "all",
    allNews = "allNews",
    allTopics = "allTopics",
    allowNotificationsWarning = "allowNotificationsWarning",
    alreadyHaveAccount = "alreadyHaveAccount",
    amount = "amount",
    annualReportPending = "annualReportPending",
    annualReportSuccess = "annualReportSuccess",
    apply = "apply",
    applyChanges = "applyChanges",
    areYouSureYouWantDelete = "areYouSureYouWantDelete",
    asset = "asset",
    assetCurrency = "assetCurrency",
    at = "at",
    atRate = "atRate",
    attention = "attention",
    authentication = "authentication",
    availablePromotions = "availablePromotions",
    availableVerificationMethods = "availableVerificationMethods",
    back = "back",
    backOffice = "backOffice",
    backTo = "backTo",
    bannerBoChatReopenBtn = "banner.boChatReopen.btn",
    bannerBoChatReopenNewMessages = "banner.boChatReopen.newMessages",
    bannerSignDeclarationBtn = "banner.signDeclaration.btn",
    bannerSignDeclarationInfo = "banner.signDeclaration.info",
    bannersAccountAccountPendingApproval = "banners.account.accountPendingApproval",
    bannersAccountCompleteYourAccount = "banners.account.completeYourAccount",
    bannersAccountLastStep = "banners.account.lastStep",
    bannersAccountProblemWithVerificationDocument = "banners.account.problemWithVerificationDocument",
    bannersAccountReadyToStart = "banners.account.readyToStart",
    bannersAccountTopUpAccount = "banners.account.topUpAccount",
    bannersAccountUpdateDocuments = "banners.account.updateDocuments",
    bannersAccountVerifyYourIdentity = "banners.account.verifyYourIdentity",
    bannersBecomeAProfessionalBecomeAProfessional = "banners.becomeAProfessional.becomeAProfessional",
    bannersBecomeAProfessionalDidNotMeetCriteria = "banners.becomeAProfessional.didNotMeetCriteria",
    bannersBecomeAProfessionalInfo = "banners.becomeAProfessional.info",
    bannersBecomeAProfessionalLearnMore = "banners.becomeAProfessional.learnMore",
    bannersBecomeAProfessionalTitle = "banners.becomeAProfessional.title",
    bannersLiquidationInfo = "banners.liquidation.info",
    bannersLiquidityTitle = "banners.liquidity.title",
    bannersMarginCallInfo = "banners.marginCall.info",
    bannersWithdrawalCancelRequest = "banners.withdrawal.cancelRequest",
    bannersWithdrawalDocumentsAreRequired = "banners.withdrawal.documentsAreRequired",
    bannersWithdrawalProcessingWithdrawal = "banners.withdrawal.processingWithdrawal",
    bannersWithdrawalSuccessInfo = "banners.withdrawal.successInfo",
    bannersWithdrawalSuccessfullyProcessed = "banners.withdrawal.successfullyProcessed",
    bannersWithdrawalUploadDocuments = "banners.withdrawal.uploadDocuments",
    bannersWithdrawalViewDetails = "banners.withdrawal.viewDetails",
    bigMovement = "bigMovement",
    biometricAuthDescriptionAndroid = "biometricAuth.description.android",
    biometricAuthDescriptionIos = "biometricAuth.description.ios",
    biometricAuthentication = "biometricAuthentication",
    biometricChangeYourMind = "biometric.changeYourMind",
    biometricConfirmation = "biometric.confirmation",
    biometricDisableFailed = "biometric.disable.failed",
    biometricDisabled = "biometric.disabled",
    biometricEnableFailed = "biometric.enable.failed",
    biometricFaceId = "biometric.face.id",
    biometricFingerprintFaceId = "biometric.fingerprintFace.id",
    biometricQuestion = "biometric.question",
    biometricSetup = "biometric.setup",
    biometricSetupInfo = "biometric.setup.info",
    biometricSubtitle = "biometric.subtitle",
    biometricTouchId = "biometric.touch.id",
    biometricTurnOn = "biometric.turn.on",
    breakout = "breakout",
    brokerNotFound = "brokerNotFound",
    browserVersionNotSupported = "browserVersionNotSupported",
    buy = "buy",
    buyEntryLimit = "buyEntryLimit",
    callMe = "callMe",
    callMeRequested = "callMeRequested",
    cancel = "cancel",
    cancelOrderReason1 = "cancel.order.reason.1",
    cancelOrderReason2 = "cancel.order.reason.2",
    cancelOrderReason3 = "cancel.order.reason.3",
    cancelOrderReason4 = "cancel.order.reason.4",
    cancelOrderReason5 = "cancel.order.reason.5",
    cancelOrderReason6 = "cancel.order.reason.6",
    cancelOrderReason7 = "cancel.order.reason.7",
    cancelOrderReason8 = "cancel.order.reason.8",
    cancelOrderReason9 = "cancel.order.reason.9",
    cancelled = "cancelled",
    cancelledOrder = "cancelledOrder",
    change = "change",
    changePassword = "changePassword",
    changePasswordNewPassword = "changePassword.newPassword",
    changePasswordSubTitle = "changePassword.subTitle",
    changePerc = "changePerc",
    changeTitleAlert = "changeTitleAlert",
    chart = "chart",
    chartPattern = "chartPattern",
    chartPatterns = "chartPatterns",
    chatCollapse = "chat.collapse",
    chatDisconnected = "chat.disconnected",
    chatGreeting = "chat.greeting",
    chatInfo = "chat.info",
    chatInit = "chat.init",
    chatInitManager = "chat.init.manager",
    chatStart = "chat.start",
    chatTitle = "chat.title",
    chatTypeMessage = "chat.typeMessage",
    checkYourInbox = "checkYourInbox",
    checkYourInboxConfirmAccount = "checkYourInbox.confirmAccount",
    checkYourInboxEmailSentFeedback = "checkYourInbox.emailSentFeedback",
    chooseLanguage = "chooseLanguage",
    choosePreferedMethod = "choosePreferedMethod",
    clear = "clear",
    clearAll = "clearAll",
    clearFilters = "clearFilters",
    close = "close",
    closeAnyway = "closeAnyway",
    closeAt = "closeAt",
    closeOnlyDate = "closeOnlyDate",
    closeOrderAt = "closeOrderAt",
    closeOutMethodChanged = "closeOutMethodChanged",
    closeRate = "closeRate",
    closeTrade = "closeTrade",
    closeTradeUpper = "closeTradeUpper",
    closeTrades = "closeTrades",
    closeTradesUpper = "closeTradesUpper",
    closed = "closed",
    closedDeal = "closedDeal",
    closedPositions = "closedPositions",
    closedTradesNoTrades = "closedTrades.noTrades",
    comission = "comission",
    commonPractice = "commonPractice",
    compactListView = "compactListView",
    confirm = "confirm",
    congratulations = "congratulations",
    connect = "connect",
    consecutiveCandles = "consecutiveCandles",
    contactMyAgent = "contactMyAgent",
    continentAf = "continent.af",
    continentAs = "continent.as",
    continentEu = "continent.eu",
    continentNa = "continent.na",
    continentOc = "continent.oc",
    continentSa = "continent.sa",
    continue = "continue",
    continueWith = "continueWith",
    copiedToClipboard = "copiedToClipboard",
    copy = "copy",
    copyToTrade = "copyToTrade",
    courses = "courses",
    createAccount = "createAccount",
    createTrade = "createTrade",
    created = "created",
    creditCardDeclaration = "creditCardDeclaration",
    creditCardDeclarationAgreement = "creditCardDeclaration.agreement",
    creditCardDeclarationInfo = "creditCardDeclaration.info",
    cryptocurrencyCalendar = "cryptocurrencyCalendar",
    currency = "currency",
    currencyStrengthMeter = "currencyStrengthMeter",
    current = "current",
    currentRate = "currentRate",
    currentRates = "currentRates",
    data = "data",
    dataIsRelevantFor = "dataIsRelevantFor",
    date = "date",
    dateRange = "dateRange",
    day = "day",
    days = "days",
    deal = "deal",
    dealingApprovingActions = "dealing.approving.actions",
    dealingApprovingAgreement = "dealing.approving.agreement",
    dealingDealsWereCreated = "dealing.deals.were.created",
    dealingDealsWereUpdated = "dealing.deals.were.updated",
    deleteAlert = "deleteAlert",
    deleteOrder = "deleteOrder",
    deleteOrderUpper = "deleteOrderUpper",
    deleteOrders = "deleteOrders",
    deleteOrdersUpper = "deleteOrdersUpper",
    deposit = "deposit",
    depositNow = "deposit.now",
    deselectAll = "deselectAll",
    details = "details",
    disclaimer = "disclaimer",
    disclaimerSignup = "disclaimer.signup",
    discounts = "discounts",
    documentTitleAcademy = "document.title.academy",
    documentTitleAccountOverview = "document.title.accountOverview",
    documentTitleAccountSettings = "document.title.accountSettings",
    documentTitleAlerts = "document.title.alerts",
    documentTitleCryptocurrencyCalendar = "document.title.cryptocurrencyCalendar",
    documentTitleCurrencyStrengthMeter = "document.title.currencyStrengthMeter",
    documentTitleEconomicCalendar = "document.title.economicCalendar",
    documentTitleFollowedAccount = "document.title.followedAccount",
    documentTitleFollowedAccounts = "document.title.followedAccounts",
    documentTitleGlossary = "document.title.glossary",
    documentTitleLessons = "document.title.lessons",
    documentTitleMarketAnalysis = "document.title.marketAnalysis",
    documentTitleMarketHighlightsTV = "document.title.marketHighlightsTV",
    documentTitleMarketResearchVideos = "document.title.marketResearchVideos",
    documentTitleMarketScanners = "document.title.marketScanners",
    documentTitleMyTrades = "document.title.myTrades",
    documentTitleNews = "document.title.news",
    documentTitlePlaylist = "document.title.playlist",
    documentTitlePromotions = "document.title.promotions",
    documentTitleSignals = "document.title.signals",
    documentTitleSymbolSearch = "document.title.symbolSearch",
    documentTitleSymbols = "document.title.symbols",
    documentTitleTechnicalAnalysis = "document.title.technicalAnalysis",
    documentTitleTechnicalAnalysisArticles = "document.title.technicalAnalysisArticles",
    documentTitleTools = "document.title.tools",
    documentTitleTrade = "document.title.trade",
    documentTitleTradingOpportunities = "document.title.tradingOpportunities",
    documentTitleTradingSignals = "document.title.tradingSignals",
    documentTitleTrendAnalysis = "document.title.trendAnalysis",
    done = "done",
    dontHaveAccount = "dontHaveAccount",
    ebooks = "ebooks",
    economicCalendar = "economicCalendar",
    edit = "edit",
    editAlert = "editAlert",
    editOrder = "editOrder",
    editTrade = "editTrade",
    eduConnectBroker = "eduConnectBroker",
    eduTradingBrokerModalLearnMore = "eduTradingBrokerModalLearnMore",
    eduTradingBrokerModalTerms = "eduTradingBrokerModalTerms",
    educationalTrade = "educationalTrade",
    email = "email",
    emerging = "emerging",
    empty = "empty",
    enable = "enable",
    enable2FA = "enable2FA",
    endChat = "endChat",
    enterToken = "enterToken",
    enterYourEmail = "enterYourEmail",
    entry = "entry",
    entryOrder = "entryOrder",
    entryOrderDescription = "entryOrderDescription",
    entryOrderNotFound = "entryOrderNotFound",
    errorFieldsMandatory = "error.fields.mandatory",
    errorGeneral = "error.general",
    errorHandlerPageMessage = "error.handlerPageMessage",
    errorHandlerPageReloadButtonText = "error.handlerPageReloadButtonText",
    errorLimitReached = "error.limit.reached",
    errorModalRequired = "error.modal.required",
    errorPasswordsNotMatch = "error.passwords.not.match",
    errorTradingHours = "error.trading.hours",
    errorUserNotFound = "error.user.not.found",
    estimatedAmount = "estimatedAmount",
    estimatedLoss = "estimatedLoss",
    estimatedProfit = "estimatedProfit",
    estimatedRate = "estimatedRate",
    event = "event",
    executedOrder = "executedOrder",
    expandedView = "expandedView",
    expirationDate = "expirationDate",
    expirationDay = "expiration.day",
    expirationMonth = "expiration.month",
    expirationYear = "expiration.year",
    export = "export",
    exposureOutOf = "exposure.outOf",
    failedClosePosition = "failedClosePosition",
    failedClosePositionDescription = "failedClosePositionDescription",
    features = "features",
    feddbackSuccessMessage = "feddback.successMessage",
    feedback = "feedback",
    feedbackFailedMessage = "feedback.failedMessage",
    feedbackHelpUs = "feedback.helpUs",
    feedbackQuestion = "feedback.question",
    feedbackSuccess = "feedback.success",
    feedbackTryAgain = "feedback.tryAgain",
    feedbackYourComment = "feedback.yourComment",
    fibonacci = "fibonacci",
    fibonacciPatterns = "fibonacciPatterns",
    filterAll = "filter.all",
    filterNextMonth = "filter.nextMonth",
    filterNextWeek = "filter.NextWeek",
    filterThisMonth = "filter.thisMonth",
    filterThisWeek = "filter.thisWeek",
    filterThisYear = "filter.thisYear",
    filterToday = "filter.today",
    filters = "filters",
    financialNews = "financialNews",
    financialSummary = "financialSummary",
    followedAccount = "followedAccount",
    followedAccountBlocked = "followedAccount.blocked",
    followedAccountInList = "followedAccount.inList",
    followedAccountNoToken = "followedAccount.noToken",
    followedAccountNotAdded = "followedAccount.notAdded",
    followedAccounts = "followedAccounts",
    forceUpdateUp = "forceUpdateUp",
    forecast = "forecast",
    forgotPassword = "forgotPassword",
    formFieldErrorEmail = "form.field.error.Email",
    formFieldErrorFullName = "form.field.error.FullName",
    formFieldErrorPassword = "form.field.error.Password",
    formFieldErrorPhoneNumber = "form.field.error.PhoneNumber",
    formFieldErrorReferralCode = "form.field.error.ReferralCode",
    frequency = "frequency",
    funds = "funds",
    getNotified = "getNotified",
    glossary = "glossary",
    goBack = "goBack",
    goToDemo = "goToDemo",
    goToReal = "goToReal",
    goToSettings = "goToSettings",
    goodAfternoon = "goodAfternoon",
    goodEvening = "goodEvening",
    goodMorning = "goodMorning",
    goodNight = "goodNight",
    great = "great",
    greatNews = "greatNews",
    headerAccountDetails = "header.account.details",
    headerAccountEquity = "header.accountEquity",
    headerActualEquity = "header.actualEquity",
    headerAvailableLine = "header.availableLine",
    headerAvailableMargin = "header.availableMargin",
    headerBalance = "header.balance",
    headerCashDividends = "header.cashDividends",
    headerCashback = "header.cashback",
    headerCommissions = "header.commissions",
    headerDeposits = "header.deposits",
    headerDescriptionAccountEquity = "header.description.accountEquity",
    headerDescriptionAvailableLine = "header.description.availableLine",
    headerDescriptionAvailableMargin = "header.description.availableMargin",
    headerDescriptionBalance = "header.description.balance",
    headerDescriptionExposure = "header.description.exposure",
    headerDescriptionMarginLevel = "header.description.marginLevel",
    headerDescriptionRealizedProfit = "header.description.realizedProfit",
    headerDescriptionUnrealizedProfit = "header.description.unrealizedProfit",
    headerDescriptionUsedMargin = "header.description.usedMargin",
    headerDescriptionUsedMarginWithMaintenance = "header.description.usedMarginWithMaintenance",
    headerDescriptionWithdrawableEquity = "header.description.withdrawableEquity",
    headerExposure = "header.exposure",
    headerFees = "header.fees",
    headerItem = "header.item",
    headerMaintenanceMargin = "header.maintenanceMargin",
    headerManualAdjustments = "header.manualAdjustments",
    headerMarginLevel = "header.marginLevel",
    headerMenuAccountDetails = "header.menu.account.details",
    headerNegativeBalanceProtection = "header.negativeBalanceProtection",
    headerRealizedProfit = "header.realizedProfit",
    headerRolloverPaid = "header.rolloverPaid",
    headerStockSplitRounding = "header.stockSplitRounding",
    headerTaxes = "header.taxes",
    headerTotalMarketValueOfOpenedPositions = "header.totalMarketValueOfOpenedPositions",
    headerTradingBenefits = "header.tradingBenefits",
    headerTransfers = "header.transfers",
    headerUnrealizedProfit = "header.unrealizedProfit",
    headerUsedMargin = "header.usedMargin",
    headerWithdrawableEquity = "header.withdrawableEquity",
    headerWithdrawals = "header.withdrawals",
    high = "high",
    highestRateToday = "highestRateToday",
    home = "home",
    hour = "hour",
    hours = "hours",
    id = "id",
    impact = "impact",
    indicator = "indicator",
    info = "info",
    installForFree = "installForFree",
    interval = "interval",
    invalidToken = "invalidToken",
    investAccount = "invest.account",
    investAccountCongratulations = "invest.account.congratulations",
    investAccountLogin = "invest.account.login",
    investAccountOpen = "invest.account.open",
    investAccountOpenAccount = "invest.account.openAccount",
    investAccountSignUp = "invest.account.sign.up",
    investAccountStep = "invest.account.step",
    investAccountTC = "invest.account.TC",
    investAccountTimeToFund = "invest.account.timeToFund",
    investAccountWelcome = "invest.account.welcome",
    keyLevel = "keyLevel",
    keyLevels = "keyLevels",
    language = "language",
    languageUpdate = "language.update",
    lastPrice = "lastPrice",
    latestNews = "latestNews",
    learnMore = "learnMore",
    learnMoreAbout = "learnMoreAbout",
    legal = "legal",
    lessonsPlatformTakeAWalk = "lessons.platform.takeAWalk",
    lessonsVideos = "lessons.videos",
    lessonsVideosNotFound = "lessons.videosNotFound",
    lessonsViewed = "lessons.viewed",
    letsTrade = "letsTrade",
    leverageChanged = "leverageChanged",
    leverageDisclaimerCheckbox = "leverage.disclaimer.checkbox",
    leverageDisclaimerMessage = "leverage.disclaimer.message",
    leverageDisclaimerTitle = "leverage.disclaimer.title",
    listingDate = "listingDate",
    loadMore = "loadMore",
    loading = "loading",
    loggedAs = "loggedAs",
    loginBtn = "loginBtn",
    loginTitle = "loginTitle",
    loginWith = "loginWith",
    logout = "logout",
    logoutConfirmation = "logoutConfirmation",
    longTerm = "longTerm",
    low = "low",
    lowestRateToday = "lowestRateToday",
    market = "market",
    marketClosed = "marketClosed",
    marketEntryOrdersDiff = "marketEntryOrdersDiff",
    marketHighlightsTV = "marketHighlightsTV",
    marketHolidayClosed = "marketHolidayClosed",
    marketOrder = "marketOrder",
    marketOrderSubmitted = "marketOrderSubmitted",
    marketResearchVideos = "marketResearchVideos",
    maxUnits = "maxUnits",
    maybeLater = "maybeLater",
    menuAcademy = "menu.academy",
    menuAccountOverview = "menu.accountOverview",
    menuAccountSettings = "menu.accountSettings",
    menuAlerts = "menu.alerts",
    menuEconomicCalendar = "menu.economicCalendar",
    menuFollowedAccounts = "menu.followedAccounts",
    menuHome = "menu.home",
    menuLessons = "menu.lessons",
    menuLiveChat = "menu.liveChat",
    menuLogs = "menu.logs",
    menuMarketAnalysis = "menu.marketAnalysis",
    menuMarketScanners = "menu.marketScanners",
    menuMyTrades = "menu.myTrades",
    menuNews = "menu.news",
    menuPromotions = "menu.promotions",
    menuSignals = "menu.signals",
    menuSupport = "menu.support",
    menuSymbols = "menu.symbols",
    menuTelegramChat = "menu.telegramChat",
    menuTools = "menu.tools",
    menuTrade = "menu.trade",
    menuTradingOpportunities = "menu.tradingOpportunities",
    methodEmailMe = "method.email.me",
    methodPhoneCallMe = "method.phoneCall.me",
    methodSmsMe = "method.sms.me",
    minAmount = "minAmount",
    minUnits = "minUnits",
    minimumUnits = "minimumUnits",
    minute = "minute",
    minutes = "minutes",
    minutesComplete = "minutesComplete",
    moreFilters = "moreFilters",
    moreInfo = "moreInfo",
    myAccount = "myAccount",
    myTrades = "myTrades",
    myTradesActivityLog = "myTrades.activityLog",
    myTradesAvailableFeatures = "myTrades.availableFeatures",
    myTradesClosedTrade = "myTrades.closedTrade",
    myTradesClosedTrades = "myTrades.closedTrades",
    myTradesEntryOrders = "myTrades.entryOrders",
    myTradesFinancialSummary = "myTrades.financialSummary",
    myTradesNoActiveTrades = "myTrades.noActiveTrades",
    myTradesOpenTrades = "myTrades.openTrades",
    myTradesOrdersHistory = "myTrades.ordersHistory",
    myWatchlist = "myWatchlist",
    myWatchlistAlerts = "myWatchlist.alerts",
    netDeposits = "netDeposits",
    newOrder = "newOrder",
    newsNotFound = "news.notFound",
    newsNothingToShow = "news.nothingToShow",
    next = "next",
    nextLevelUnlock = "nextLevelUnlock",
    nextLevelUnlockTradingGroup = "nextLevelUnlockTradingGroup",
    nextWeek = "nextWeek",
    no = "no",
    noActiveAlerts = "noActiveAlerts",
    noActiveTrades = "noActiveTrades",
    noInternetConnection = "noInternetConnection",
    noOrdersHistory = "noOrdersHistory",
    noResultsActivityLogs = "noResultsActivityLogs",
    noResultsClosedPositions = "noResultsClosedPositions",
    noResultsEntryOrders = "noResultsEntryOrders",
    noResultsOpenPositions = "noResultsOpenPositions",
    noResultsSignals = "noResultsSignals",
    noResultsSignalsWatchList = "noResultsSignalsWatchList",
    noWatchSymbols = "noWatchSymbols",
    notAllowedToTradeError = "notAllowedToTradeError",
    notEnoughMargin = "notEnoughMargin",
    notNow = "notNow",
    nothingToShow = "nothingToShow",
    notificationTabAll = "notification.tab.all",
    notificationTabUnread = "notification.tab.unread",
    notifications = "notifications",
    oco = "oco",
    ocoRate = "ocoRate",
    ocoSlTp = "ocoSlTp",
    ok = "ok",
    oops = "oops",
    openAccount = "openAccount",
    openRateToday = "openRateToday",
    opened = "opened",
    or = "or",
    order = "order",
    orderCreated = "order.created",
    orderDeleted = "orderDeleted",
    orderDetails = "order.details",
    orderExecuted = "orderExecuted",
    orderFailedToDelete = "orderFailedToDelete",
    orderIsActiveEntryLimit = "orderIsActiveEntryLimit",
    orderIsActiveMarket = "orderIsActiveMarket",
    orderIsCancelling = "orderIsCancelling",
    orderIsInActive = "orderIsInActive",
    orderPlaced = "orderPlaced",
    orderRate = "orderRate",
    orderRateRange = "orderRateRange",
    orderStatus = "orderStatus",
    orderStatusCancelled = "orderStatus.Cancelled",
    orderStatusExecuted = "orderStatus.Executed",
    orderUnits = "orderUnits",
    orderUpdated = "orderUpdated",
    orders = "orders",
    outstandingShares = "outstandingShares",
    password = "password",
    passwordChanged = "password.changed",
    passwordHasExpired = "passwordHasExpired",
    passwordNew = "password.new",
    passwordNewRetype = "password.new.retype",
    pattern = "pattern",
    patternTypes = "patternTypes",
    personalinfo = "personalinfo",
    phone = "phone",
    placeOrder = "placeOrder",
    pleaseSelectPatternType = "pleaseSelectPatternType",
    popupMessageSwitchToLive = "popup.message.switchToLive",
    position = "position",
    positionNotFound = "positionNotFound",
    positions = "positions",
    positionsNoOpenPositions = "positions.noOpenPositions",
    previous = "previous",
    price = "price",
    priceTitleAlert = "priceTitleAlert",
    pricesIn = "pricesIn",
    probability = "probability",
    profile = "profile",
    promosPromosForTheAccount = "promos.promosForTheAccount",
    promosPromosForThisPosition = "promos.promosForThisPosition",
    promotionBonus = "promotion.bonus",
    promotionChangedInbox = "promotionChangedInbox",
    promotionCreditConfirmReject = "promotion.credit.confirm.reject",
    promotionCreditDescription = "promotion.credit.description",
    promotionCreditReceived = "promotion.credit.received",
    promotionCreditRejected = "promotion.credit.rejected",
    promotionDescriptionEducational = "promotion.description.educational",
    promotionDescriptionPosition_insurance = "promotion.description.position_insurance",
    promotionDescriptionProtected = "promotion.description.protected",
    promotionDescriptionRebate_plan = "promotion.description.rebate_plan",
    promotionDescriptionRolling_contract = "promotion.description.rolling_contract",
    promotionDescriptionRollover_discount = "promotion.description.rollover_discount",
    promotionDescriptionSpread_cashback = "promotion.description.spread_cashback",
    promotionDescriptionVault = "promotion.description.vault",
    promotionEducationalLongDescription = "promotion.educational.longDescription",
    promotionEducationalPositionRemaining = "promotion.educational.positionRemaining",
    promotionExpirationDate = "promotion.expirationDate",
    promotionExpireOn = "promotion.expireOn",
    promotionInfoEducational = "promotion.info.educational",
    promotionInfoPosition_insurance = "promotion.info.position_insurance",
    promotionInfoProtected = "promotion.info.protected",
    promotionInfoRebate_plan = "promotion.info.rebate_plan",
    promotionInfoRolling_contract = "promotion.info.rolling_contract",
    promotionInfoRollover_discount = "promotion.info.rollover_discount",
    promotionInfoSpread_cashback = "promotion.info.spread_cashback",
    promotionInfoVault = "promotion.info.vault",
    promotionInsuranceLongDescription = "promotion.insurance.longDescription",
    promotionInsurancePositionRemaining = "promotion.insurance.positionRemaining",
    promotionModalNoPromotions = "promotion.modal.noPromotions",
    promotionModalRebatePlanInfo = "promotion.modal.rebatePlan.info",
    promotionModalRebatePlanInfoLastLevel = "promotion.modal.rebatePlan.info.last.level",
    promotionModalRebatePlanInfoReachedLastLevel = "promotion.modal.rebatePlan.info.reached.last.level",
    promotionModalRebatePlanInfoReachedNextLevel = "promotion.modal.rebatePlan.info.reached.next.level",
    promotionModalRebatePlanSubtitle = "promotion.modal.rebatePlan.subtitle",
    promotionModalVaultCashoutAmount = "promotion.modal.vault.cashoutAmount",
    promotionModalVaultCashoutVolumeL30d = "promotion.modal.vault.cashoutVolumeL30d",
    promotionModalVaultCurrentCashoutVolume = "promotion.modal.vault.currentCashoutVolume",
    promotionModalVaultEquity = "promotion.modal.vault.equity",
    promotionModalVaultExpires = "promotion.modal.vault.expires",
    promotionModalVaultExpiresInfo1 = "promotion.modal.vault.expires.info.1",
    promotionModalVaultExpiresInfo2 = "promotion.modal.vault.expires.info.2",
    promotionModalVaultTc = "promotion.modal.vault.tc",
    promotionOverviewPosition_insurance = "promotion.overview.position_insurance",
    promotionOverviewRebate_plan = "promotion.overview.rebate_plan",
    promotionOverviewRollover_discount = "promotion.overview.rollover_discount",
    promotionOverviewSpread_cashback = "promotion.overview.spread_cashback",
    promotionOverviewVault = "promotion.overview.vault",
    promotionProtectedLongDescription = "promotion.protected.longDescription",
    promotionProtectedPositionRemaining = "promotion.protected.positionRemaining",
    promotionRollOverLongDescription = "promotion.RollOver.longDescription",
    promotionRollingContractLongDescription = "promotion.rollingContract.longDescription",
    promotionSpreadCashbackLongDescription = "promotion.spreadCashback.longDescription",
    promotionSpreadCashbackPositionRemaining = "promotion.spreadCashback.positionRemaining",
    promotionSubtitleEducational = "promotion.subtitle.educational",
    promotionSubtitlePosition_insurance = "promotion.subtitle.position_insurance",
    promotionSubtitleProtected = "promotion.subtitle.protected",
    promotionSubtitleRebate_plan = "promotion.subtitle.rebate_plan",
    promotionSubtitleRolling_contract = "promotion.subtitle.rolling_contract",
    promotionSubtitleRollover_discount = "promotion.subtitle.rollover_discount",
    promotionSubtitleSpread_cashback = "promotion.subtitle.spread_cashback",
    promotionSubtitleVault = "promotion.subtitle.vault",
    promotionTitleEducational = "promotion.title.educational",
    promotionTitlePosition_insurance = "promotion.title.position_insurance",
    promotionTitleProtected = "promotion.title.protected",
    promotionTitleRebate_plan = "promotion.title.rebate_plan",
    promotionTitleRolling_contract = "promotion.title.rolling_contract",
    promotionTitleRollover_discount = "promotion.title.rollover_discount",
    promotionTitleSpread_cashback = "promotion.title.spread_cashback",
    promotionTitleVault = "promotion.title.vault",
    promotionTitleVaultWarning = "promotion.title.vaultWarning",
    promotionVaultWarning = "promotion.vault.warning",
    protected = "protected",
    protectedTrade = "protectedTrade",
    protectedTradesLeft = "protectedTradesLeft",
    provideSignature = "provideSignature",
    qauntumAccount = "qauntum.account",
    quantumAccountCongratulations = "quantum.account.congratulations",
    quantumAccountExpire = "quantum.account.expire",
    quantumAccountOpen = "quantum.account.open",
    quantumAccountSignUp = "quantum.account.signUp",
    quantumAccountStep = "quantum.account.Step",
    quantumAccountSwitch = "quantum.account.switch",
    quantumAccountTC = "quantum.account.TC",
    range = "range",
    rateAtValue = "rateAtValue",
    readBook = "readBook",
    reason = "reason",
    recoveryOfYourAccount = "recoveryOfYourAccount",
    recurringInfo = "recurringInfo",
    reject = "reject",
    rememberMe = "rememberMe",
    remove = "remove",
    removeAccount = "removeAccount",
    removeAccountQuestion = "removeAccount.question",
    removeFromWatchlist = "removeFromWatchlist",
    removedFromYourWatchlist = "removedFromYourWatchlist",
    required = "required",
    requiredFunds = "requiredFunds",
    requiredMargin = "requiredMargin",
    resendCode = "resendCode",
    resendEmail = "resendEmail",
    resentNext = "resentNext",
    resetPassword = "resetPassword",
    resetYourPassword = "resetYourPassword",
    reviewMessage = "reviewMessage",
    reviews = "reviews",
    risingWedge = "risingWedge",
    risingWedgeEmerging = "risingWedgeEmerging",
    rolloverFees = "rolloverFees",
    rolloverFeesConversion = "rolloverFeesConversion",
    rolloverFeesRolloverConversion = "rolloverFeesRolloverConversion",
    save = "save",
    saveData = "saveData",
    search = "search",
    searchAccount = "searchAccount",
    searchSymbol = "searchSymbol",
    second = "second",
    seconds = "seconds",
    seeBenefits = "seeBenefits",
    selectAll = "selectAll",
    selectBroker = "selectBroker",
    selectCountryCode = "selectCountryCode",
    selectMarginLevel = "selectMarginLevel",
    selectPeriod = "selectPeriod",
    selectSymbol = "selectSymbol",
    selectSymbols = "selectSymbols",
    sell = "sell",
    sellEntryLimit = "sellEntryLimit",
    sendVerificationCode = "sendVerificationCode",
    serverErrorLimitReached = "server.error.limit.reached",
    setAnAlert = "setAnAlert",
    settings = "settings",
    settingsAnnualAnnualStatement = "settings.annual.annualStatement",
    settingsAnnualChooseYear = "settings.annual.chooseYear",
    settingsAnnualInfo = "settings.annual.info",
    settingsAnnualSendToEmail = "settings.annual.sendToEmail",
    settingsChangePswConfirmPsw = "settings.changePsw.ConfirmPsw",
    settingsChangePswCurrentPsw = "settings.changePsw.currentPsw",
    settingsChangePswNewPsw = "settings.changePsw.newPsw",
    settingsCloseOutChangeCloseOutMethod = "settings.closeOut.changeCloseOutMethod",
    settingsCloseOutChooseMethod = "settings.closeOut.chooseMethod",
    settingsCloseOutCurrentMethod = "settings.closeOut.currentMethod",
    settingsCloseOutInfo = "settings.closeOut.info",
    settingsCloseOutPolicyInfo = "settings.closeOut.policyInfo",
    settingsCloseOutTitle = "settings.closeOut.title",
    settingsCompleteRegistration = "settings.complete.registration",
    settingsCompleteRegistrationInfo = "settings.complete.registration.info",
    settingsFundsAvailableMargin = "settings.funds.availableMargin",
    settingsFundsBalance = "settings.funds.balance",
    settingsFundsCurrentMarginLevel = "settings.funds.currentMarginLevel",
    settingsFundsUsedMargin = "settings.funds.usedMargin",
    settingsMyLeverageChangeMyLeverage = "settings.myLeverage.changeMyLeverage",
    settingsMyLeverageConfirm = "settings.myLeverage.confirm",
    settingsMyLeverageCurrentRatio = "settings.myLeverage.currentRatio",
    settingsMyLeverageInfo = "settings.myLeverage.info",
    settingsMyLeverageMaxLeverage = "settings.myLeverage.maxLeverage",
    settingsMyLeverageReminder = "settings.myLeverage.reminder",
    settingsMyLeverageTitle = "settings.myLeverage.title",
    settingsProfessionalBecomePro = "settings.professional.becomePro",
    settingsProfessionalInfo = "settings.professional.info",
    settingsProfessionalTitle = "settings.professional.title",
    settingsProfileAccountNumber = "settings.profile.accountNumber",
    settingsProfileAddress = "settings.profile.address",
    settingsProfileBrandEmails = "settings.profile.brandEmails",
    settingsProfileCommunicationPreferences = "settings.profile.communicationPreferences",
    settingsProfileCountryCode = "settings.profile.countryCode",
    settingsProfileCountryOfBirth = "settings.profile.countryOfBirth",
    settingsProfileDarkTheme = "settings.profile.darkTheme",
    settingsProfileDateOfBirth = "settings.profile.dateOfBirth",
    settingsProfileDocuments = "settings.profile.documents",
    settingsProfileDocumentsContact = "settings.profile.documents.contact",
    settingsProfileDocumentsUpload = "settings.profile.documents.upload",
    settingsProfileDocumentsVerified = "settings.profile.documents.verified",
    settingsProfileEmail = "settings.profile.email",
    settingsProfileGetCode = "settings.profile.getCode",
    settingsProfileInvalidEmail = "settings.profile.invalidEmail",
    settingsProfileInvalidPhoneNumber = "settings.profile.invalidPhoneNumber",
    settingsProfileJoinTelegramBot = "settings.profile.joinTelegramBot",
    settingsProfileName = "settings.profile.name",
    settingsProfileNationality = "settings.profile.nationality",
    settingsProfileNewEmail = "settings.profile.newEmail",
    settingsProfilePhoneCalls = "settings.profile.phoneCalls",
    settingsProfilePhoneNumber = "settings.profile.phoneNumber",
    settingsProfilePopupSound = "settings.profile.popupSound",
    settingsProfilePushNotifications = "settings.profile.pushNotifications",
    settingsProfileShareInformation = "settings.profile.shareInformation",
    settingsProfileSms = "settings.profile.sms",
    settingsProfileVerified = "settings.profile.verified",
    settingsProfileVerifyEmail = "settings.profile.verifyEmail",
    settingsProfileVerifyNumber = "settings.profile.verifyNumber",
    settingsTopUpAccountLimit = "settings.topUp.accountLimit",
    settingsTopUpInfo = "settings.topUp.info",
    settingsTopUpLeftToTopUp = "settings.topUp.leftToTopUp",
    settingsTopUpManageLimits = "settings.topUp.manageLimits",
    settingsTopUpTitle = "settings.topUp.title",
    settingsTransactionsHistory = "settings.transactions.history",
    settingsWithdrawalsAvailableFunds = "settings.withdrawals.availableFunds",
    settingsWithdrawalsMakeWithdrawal = "settings.withdrawals.makeWithdrawal",
    settingsWithdrawalsPendingInfo = "settings.withdrawals.pendingInfo",
    settingsWithdrawalsTitle = "settings.withdrawals.title",
    shortTerm = "shortTerm",
    showAll = "showAll",
    showLess = "showLess",
    showMore = "showMore",
    showOnlyOnesWithProbability = "showOnlyOnesWithProbability",
    sign = "sign",
    signInTitle = "signInTitle",
    signUpBtn = "signUpBtn",
    signUpErrorCode1 = "signUp.error.code.1",
    signUpErrorCode10 = "signUp.error.code.10",
    signUpErrorCode11 = "signUp.error.code.11",
    signUpErrorCode12 = "signUp.error.code.12",
    signUpErrorCode13 = "signUp.error.code.13",
    signUpErrorCode14 = "signUp.error.code.14",
    signUpErrorCode15 = "signUp.error.code.15",
    signUpErrorCode16 = "signUp.error.code.16",
    signUpErrorCode17 = "signUp.error.code.17",
    signUpErrorCode2 = "signUp.error.code.2",
    signUpErrorCode3 = "signUp.error.code.3",
    signUpErrorCode4 = "signUp.error.code.4",
    signUpErrorCode5 = "signUp.error.code.5",
    signUpErrorCode51 = "signUp.error.code.51",
    signUpErrorCode52 = "signUp.error.code.52",
    signUpErrorCode53 = "signUp.error.code.53",
    signUpErrorCode54 = "signUp.error.code.54",
    signUpErrorCode6 = "signUp.error.code.6",
    signUpErrorCode7 = "signUp.error.code.7",
    signUpErrorCode8 = "signUp.error.code.8",
    signUpErrorCode9 = "signUp.error.code.9",
    signUpFormAgreeWithThe = "signUp.form.agreeWithThe",
    signUpFormEmail = "signUp.form.email",
    signUpFormFirstName = "signUp.form.firstName",
    signUpFormFullName = "signUp.form.fullName",
    signUpFormLastName = "signUp.form.lastName",
    signUpFormPassword = "signUp.form.password",
    signUpFormPhoneNumber = "signUp.form.phoneNumber",
    signUpFormTermsAndConditions = "signUp.form.termsAndConditions",
    signUpFormVerificationCode = "signUp.form.verificationCode",
    signUpPasswordErrorRegEx0 = "signUp.password.error.RegEx0",
    signUpPasswordErrorRegEx1 = "signUp.password.error.RegEx1",
    signUpPasswordErrorRegEx2 = "signUp.password.error.RegEx2",
    signUpPasswordErrorRegEx3 = "signUp.password.error.RegEx3",
    signUpPasswordErrorRegEx4 = "signUp.password.error.RegEx4",
    signUpPasswordErrorRegEx5 = "signUp.password.error.RegEx5",
    signUpTitle = "signUpTitle",
    signals = "signals",
    signalsAlertInfo = "signalsAlertInfo",
    signalsAlertTitle = "signalsAlertTitle",
    signalsDescriptionABCD = "signals.description.ABCD",
    signalsDescriptionAscendingTriangle = "signals.description.AscendingTriangle",
    signalsDescriptionBigMovement = "signals.description.BigMovement",
    signalsDescriptionButterfly = "signals.description.Butterfly",
    signalsDescriptionChannelDelUp = "signals.description.ChannelDelUp",
    signalsDescriptionChannelDown = "signals.description.ChannelDown",
    signalsDescriptionConsecutiveCandles = "signals.description.ConsecutiveCandles",
    signalsDescriptionDescendingTriangle = "signals.description.DescendingTriangle",
    signalsDescriptionDoubleBottom = "signals.description.DoubleBottom",
    signalsDescriptionDoubleTop = "signals.description.DoubleTop",
    signalsDescriptionFallingWedge = "signals.description.FallingWedge",
    signalsDescriptionFlag = "signals.description.Flag",
    signalsDescriptionGartley = "signals.description.Gartley",
    signalsDescriptionHeadAndShoulders = "signals.description.HeadAndShoulders",
    signalsDescriptionInverseHeadAndShoulders = "signals.description.InverseHeadAndShoulders",
    signalsDescriptionPennant = "signals.description.Pennant",
    signalsDescriptionResistance = "signals.description.Resistance",
    signalsDescriptionRisingWedge = "signals.description.RisingWedge",
    signalsDescriptionSupport = "signals.description.Support",
    signalsDescriptionThreeDrive = "signals.description.ThreeDrive",
    signalsDescriptionThreePointExtension = "signals.description.ThreePointExtension",
    signalsDescriptionThreePointRetracement = "signals.description.ThreePointRetracement",
    signalsDescriptionTriangle = "signals.description.Triangle",
    signalsDescriptionTripleBottom = "signals.description.TripleBottom",
    signalsDescriptionTripleTop = "signals.description.TripleTop",
    signalsFilterABCD = "signals.filter.ABCD",
    signalsFilterAscendingTriangle = "signals.filter.AscendingTriangle",
    signalsFilterBigMovement = "signals.filter.BigMovement",
    signalsFilterButterfly = "signals.filter.Butterfly",
    signalsFilterChannelDelUp = "signals.filter.ChannelDelUp",
    signalsFilterChannelDown = "signals.filter.ChannelDown",
    signalsFilterConsecutiveCandles = "signals.filter.ConsecutiveCandles",
    signalsFilterDescendingTriangle = "signals.filter.DescendingTriangle",
    signalsFilterDoubleBottom = "signals.filter.DoubleBottom",
    signalsFilterDoubleTop = "signals.filter.DoubleTop",
    signalsFilterFallingWedge = "signals.filter.FallingWedge",
    signalsFilterFlag = "signals.filter.Flag",
    signalsFilterGartley = "signals.filter.Gartley",
    signalsFilterHeadAndShoulders = "signals.filter.HeadAndShoulders",
    signalsFilterInverseHeadAndShoulders = "signals.filter.InverseHeadAndShoulders",
    signalsFilterPennant = "signals.filter.Pennant",
    signalsFilterRectangle = "signals.filter.Rectangle",
    signalsFilterResistance = "signals.filter.Resistance",
    signalsFilterRisingWedge = "signals.filter.RisingWedge",
    signalsFilterSupport = "signals.filter.Support",
    signalsFilterThreeDrive = "signals.filter.ThreeDrive",
    signalsFilterThreePointExtension = "signals.filter.ThreePointExtension",
    signalsFilterThreePointRetracement = "signals.filter.ThreePointRetracement",
    signalsFilterTriangle = "signals.filter.Triangle",
    signalsFilterTripleBottom = "signals.filter.TripleBottom",
    signalsFilterTripleTop = "signals.filter.TripleTop",
    signalsUnlockAccessInformation = "signals.unlock.accessInformation",
    signalsUnlockContactAgent = "signals.unlock.contactAgent",
    signalsUnlockDoneMsg = "signals.unlock.doneMsg",
    significance = "significance",
    skipEducationMode = "skipEducationMode",
    slTpRateRange = "slTpRateRange",
    source = "source",
    specialOffers = "specialOffers",
    startTrading = "startTrading",
    stockExchange = "stockExchange",
    stop = "stop",
    stopLoss = "stopLoss",
    stopLossDescription = "stopLossDescription",
    stopLossEstimated = "stopLossEstimated",
    stopLossOutOfRange = "stopLossOutOfRange",
    stopLossSubtitle = "stopLossSubtitle",
    submit = "submit",
    summary = "summary",
    supportSubtitle = "supportSubtitle",
    symbolCategoryCommodity = "symbol.category.Commodity",
    symbolCategoryCrypto = "symbol.category.Crypto",
    symbolCategoryCurrency = "symbol.category.Currency",
    symbolCategoryDerivatives = "symbol.category.Derivatives",
    symbolCategoryFX = "symbol.category.FX",
    symbolCategoryIndex = "symbol.category.Index",
    symbolCategoryNotSet = "symbol.category.NotSet",
    symbolCategoryStock = "symbol.category.Stock",
    symbolNotFound = "symbol.notFound",
    symbols = "symbols",
    symbolsAdditionalInfo = "symbols.AdditionalInfo",
    symbolsAlerts = "symbols.alerts",
    symbolsCloseAmount = "symbols.closeAmount",
    symbolsCloseRate = "symbols.closeRate",
    symbolsContinents = "symbols.continents",
    symbolsEntryOrder = "symbols.entryOrder",
    symbolsFiltersCloseMarkets = "symbols.filters.closeMarkets",
    symbolsFiltersOpenCloseMarkets = "symbols.filters.openCloseMarkets",
    symbolsFiltersOpenMarkets = "symbols.filters.openMarkets",
    symbolsGoToPortfolio = "symbols.goToPortfolio",
    symbolsMarketOrder = "symbols.marketOrder",
    symbolsNoActiveSymbols = "symbols.noActiveSymbols",
    symbolsOrderRate = "symbols.orderRate",
    symbolsOrderType = "symbols.orderType",
    system = "system",
    takeProfit = "takeProfit",
    takeProfitDescription = "takeProfitDescription",
    takeProfitEstimated = "takeProfitEstimated",
    takeProfitOutOfRange = "takeProfitOutOfRange",
    takeProfitSubtitle = "takeProfitSubtitle",
    target = "target",
    targetPeriod = "targetPeriod",
    technicalAnalysisArticles = "technicalAnalysisArticles",
    timeRange = "timeRange",
    title = "title",
    titleAccountOverview = "title.accountOverview",
    titleAccountSettings = "title.accountSettings",
    titleAlerts = "title.alerts",
    titleEconomicCalendar = "title.economicCalendar",
    titleFollowedAccounts = "title.followedAccounts",
    titleLessons = "title.lessons",
    titleMyTrades = "title.myTrades",
    titleNews = "title.news",
    titlePromotions = "title.promotions",
    titleSignals = "title.signals",
    titleSymbols = "title.symbols",
    titleTechnicalAnalysis = "title.technicalAnalysis",
    titleTradingOpportunities = "title.tradingOpportunities",
    todayTopMovers = "todayTopMovers",
    tomorrow = "tomorrow",
    tools = "tools",
    topUp = "topUp",
    topUpAccount = "topUpAccount",
    total = "total",
    totalPLNoLogs = "totalPLNoLogs",
    totalProfit = "totalProfit",
    tourLesson1Step1Title = "tour.lesson1.step1.title",
    tourLesson1Step2Text = "tour.lesson1.step2.text",
    tourLesson1Step2Title = "tour.lesson1.step2.title",
    tourLesson1Step3Text = "tour.lesson1.step3.text",
    tourLesson1Step3Title = "tour.lesson1.step3.title",
    tourLesson1Step4Text = "tour.lesson1.step4.text",
    tourLesson1Step4Title = "tour.lesson1.step4.title",
    tourLesson1Step5Text = "tour.lesson1.step5.text",
    tourLesson1Step5Title = "tour.lesson1.step5.title",
    tourLesson1Step6Text = "tour.lesson1.step6.text",
    tourLesson1Step6Title = "tour.lesson1.step6.title",
    trade = "trade",
    tradeColumnAccountAmount = "trade.column.accountAmount",
    tradeColumnAccountCurrency = "trade.column.accountCurrency",
    tradeColumnAction = "trade.column.action",
    tradeColumnActions = "trade.column.actions",
    tradeColumnAdded = "trade.column.added",
    tradeColumnAlert = "trade.column.alert",
    tradeColumnAlertAmount = "trade.column.alertAmount",
    tradeColumnAlertBuy = "trade.column.alertBuy",
    tradeColumnAlertSell = "trade.column.alertSell",
    tradeColumnAlertType = "trade.column.alertType",
    tradeColumnAmount = "trade.column.amount",
    tradeColumnBalance = "trade.column.balance",
    tradeColumnBuy = "trade.column.buy",
    tradeColumnCardHolder = "trade.column.cardHolder",
    tradeColumnCardNumber = "trade.column.cardNumber",
    tradeColumnCardType = "trade.column.cardType",
    tradeColumnChart1d = "trade.column.chart1d",
    tradeColumnCloseDate = "trade.column.closeDate",
    tradeColumnCloseRate = "trade.column.closeRate",
    tradeColumnCloseReason = "trade.column.closeReason",
    tradeColumnCreatedDate = "trade.column.createdDate",
    tradeColumnCurrency = "trade.column.currency",
    tradeColumnDate = "trade.column.date",
    tradeColumnDescription = "trade.column.description",
    tradeColumnDirection = "trade.column.direction",
    tradeColumnExposure = "trade.column.exposure",
    tradeColumnHighLow = "trade.column.high.low",
    tradeColumnIndustry = "trade.column.industry",
    tradeColumnInterval = "trade.column.interval",
    tradeColumnMarketCap = "trade.column.marketCap",
    tradeColumnMarketRate = "trade.column.marketRate",
    tradeColumnMarketValue = "trade.column.marketValue",
    tradeColumnOpenDate = "trade.column.openDate",
    tradeColumnOpenRate = "trade.column.openRate",
    tradeColumnOrder = "trade.column.order",
    tradeColumnOrderDate = "trade.column.orderDate",
    tradeColumnOrderRate = "trade.column.orderRate",
    tradeColumnPaymentAmount = "trade.column.paymentAmount",
    tradeColumnPaymentCurrency = "trade.column.paymentCurrency",
    tradeColumnPerformedBy = "trade.column.performedBy",
    tradeColumnPrice = "trade.column.price",
    tradeColumnProbability = "trade.column.probability",
    tradeColumnRevenue = "trade.column.revenue",
    tradeColumnSell = "trade.column.sell",
    tradeColumnStopLoss = "trade.column.stopLoss",
    tradeColumnSymbol = "trade.column.symbol",
    tradeColumnTakeProfit = "trade.column.takeProfit",
    tradeColumnTarget = "trade.column.target",
    tradeColumnTime = "trade.column.time",
    tradeColumnTotalProfit = "trade.column.totalProfit",
    tradeColumnTradingEntityId = "trade.column.tradingEntityId",
    tradeColumnTrend = "trade.column.trend",
    tradeColumnTrigger = "trade.column.trigger",
    tradeColumnType = "trade.column.type",
    tradeColumnUnitsOpenRate = "trade.column.unitsOpenRate",
    tradeColumnUnitsOrderRate = "trade.column.unitsOrderRate",
    tradeColumnUnitsRate = "trade.column.unitsRate",
    tradeColumnValue = "trade.column.value",
    tradeDeleted = "tradeDeleted",
    tradeDetails = "tradeDetails",
    tradeExecuted = "tradeExecuted",
    tradeFailedToDelete = "tradeFailedToDelete",
    tradeLossCoverage = "tradeLossCoverage",
    tradeManagerTabAlerts = "trade.manager.tab.alerts",
    tradeManagerTabChart = "trade.manager.tab.chart",
    tradeManagerTabEvents = "trade.manager.tab.events",
    tradeManagerTabInfo = "trade.manager.tab.info",
    tradeManagerTabNews = "trade.manager.tab.news",
    tradeManagerTabPositions = "trade.manager.tab.positions",
    tradeManagerTabPromotions = "trade.manager.tab.promotions",
    tradeManagerTabSignals = "trade.manager.tab.signals",
    tradeManagerTabTrade = "trade.manager.tab.trade",
    tradePlaced = "tradePlaced",
    tradePlacedEntryOrder = "tradePlacedEntryOrder",
    tradeSpreadCashback = "tradeSpreadCashback",
    tradeSymbolNotification = "trade.symbolNotification",
    tradeSymbolNotificationCheckbox = "trade.symbolNotificationCheckbox",
    tradeSymbolNotificationExpire = "trade.symbolNotificationExpire",
    tradeSymbolNotificationRollConfirmation = "trade.symbolNotificationRollConfirmation",
    tradeSymbolNotificationRollFailed = "trade.symbolNotificationRollFailed",
    tradeSymbolNotificationRollSuccess = "trade.symbolNotificationRollSuccess",
    tradeUpdated = "tradeUpdated",
    tradesActivity = "tradesActivity",
    tradesCanceledBy = "tradesCanceledBy",
    tradesLeft = "tradesLeft",
    tradingCurrency = "tradingCurrency",
    tradingHours = "tradingHours",
    tradingProfile = "tradingProfile",
    tradingSignals = "tradingSignals",
    transactionHasRecorded = "transaction.hasRecorded",
    transactionsQuestion = "transactions.question",
    transactionsUnselected = "transactions.unselected",
    transferToCDF = "transfer.to.CDF",
    trend = "trend",
    trendAnalysis = "trendAnalysis",
    trends = "trends",
    trigger = "trigger",
    twoFactorAuthDescription = "twoFactorAuth.description",
    twoFactorAuthentication = "twoFactorAuthentication",
    type = "type",
    units = "units",
    unitsAt = "unitsAt",
    unlimited = "unlimited",
    upTo = "upTo",
    update = "update",
    updateAlert = "updateAlert",
    updateNow = "updateNow",
    useToken = "useToken",
    username = "username",
    usingDemo = "usingDemo",
    usualEffect = "usualEffect",
    value = "value",
    verificationCodeEmail = "verificationCodeEmail",
    verificationCodeSms = "verificationCodeSms",
    verificationDifferentOptions = "verification.differentOptions",
    verificationEmail = "verificationEmail",
    verificationError = "verificationError",
    verificationMethods = "verificationMethods",
    verificationReceivedCodeEmail = "verification.received.code.email",
    verificationReceivedCodePhoneCall = "verification.received.code.phoneCall",
    verificationReceivedCodeSms = "verification.received.code.sms",
    verificationResend = "verificationResend",
    verificationSms = "verificationSms",
    videoNotFound = "videoNotFound",
    viewAll = "viewAll",
    viewMode = "viewMode",
    viewPosition = "viewPosition",
    views = "views",
    walkThroughNotFound = "walkThroughNotFound",
    walkthroughs = "walkthroughs",
    wantToCloseTrade = "wantToCloseTrade",
    wantToCloseTrades = "wantToCloseTrades",
    wantToDeleteOrder = "wantToDeleteOrder",
    wantToDeleteOrders = "wantToDeleteOrders",
    warning = "warning",
    watchLessons = "watchLessons",
    wholeUnitsNumber = "wholeUnitsNumber",
    willDoItLater = "willDoItLater",
    withdrawalContinue = "withdrawalContinue",
    withdrawalDescriptionSuccess = "withdrawal.description.success",
    withdrawalExceeds = "withdrawalExceeds",
    withdrawalFormAmountLabel = "withdrawalFormAmountLabel",
    withdrawalListItemAmount = "withdrawalListItemAmount",
    withdrawalListItemCurrency = "withdrawalListItemCurrency",
    withdrawalListItemDate = "withdrawalListItemDate",
    withdrawalListItemId = "withdrawalListItemId",
    withdrawalListTitle = "withdrawalListTitle",
    withdrawalPrice = "withdrawalPrice",
    withdrawalReasonPlaceholder = "withdrawalReasonPlaceholder",
    withdrawalTitleCanceled = "withdrawal.title.canceled",
    withdrawalTitleStep3 = "withdrawal.title.step3",
    withdrawalTitleSuccess = "withdrawal.title.success",
    workingHours = "workingHours",
    wrongCode = "wrongCode",
    xciteDoesnSupportOS = "xciteDoesnSupportOS",
    xciteDoesnSupportOSInfo = "xciteDoesnSupportOS.info",
    yes = "yes",
    yesImSure = "yesImSure",
    yesReject = "yesReject",
    yourLevel = "yourLevel",
    yourProgress = "yourProgress",
    yourSessionExpired = "yourSessionExpired",
}
