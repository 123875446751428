import moment from "moment-mini";
import {Engine} from "platform/engine/Engine";
import {
    ClearDealFormRanges, CloseDeals,
    CloseDealsPayload,
    DealOpenEditInitialization,
    EditDealPayload,
    GoingEditDealPayload,
    GoingOpenDeal,
    GoingOpenDealPayload,
    OpenDealPayload,
    RollPositionsPayload,
    SetDealFormInProgress,
    SetDealServerError,
    SetPositionTabDealId,
    SetSymbolDetails,
    SetTradeManagerTab,
    UpdateDealForm
} from "core/redux/deal/DealReduxActions";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import {Deal} from "platform/protocol/trading/Deal";
import {TradeType} from "protocol/trade/TradeType";
import {SetTradingModal, ToggleMenu, ToggleMenuPayload} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {DealForm} from "core/redux/deal/DealReduxState";
import {XhrUtil} from "core/util/XhrUtil";
import {OpenDealRequest} from "protocol/trade/OpenDealRequest";
import {Quote} from "platform/protocol/trading/Quote";
import {PositionDirection, PositionDirection as TradeDirection} from "platform/protocol/enum/PositionDirection";
import {AccountState} from "core/state/AccountState";
import {ServiceType} from "enum/ServiceType";
import {OpenDealResponse} from "protocol/trade/OpenDealResponse";
import {HttpReject} from "platform/network/http/Http";
import {BookEntryOrderRequest} from "protocol/trade/BookEntryOrderRequest";
import Utils from "platform/util/Utils";
import Parameter from "platform/util/Parameter";
import {ClosePosition} from "protocol/trade/ClosePosition";
import {BaseResponse} from "protocol/BaseResponse";
import {CancelEntryOrder} from "protocol/trade/CancelEntryOrder";
import {CloseMultiplePositions} from "protocol/trade/CloseMultiplePositions";
import {PromotionType} from "platform/protocol/enum/PromotionType";
import {CloseMultiplePositionsResponse, PositionThatFailedToClose} from "protocol/trade/CloseMultiplePositionsResponse";
import {HideLoader, SetLoader} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import {OpenPositionExtended} from "protocol/trade/OpenPositionExtended";
import {EntryOrderExtended} from "protocol/trade/EntryOrderExtended";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import DateTimeFormat from "core/format/DateTimeFormat";
import {UpdatePositionRequest} from "protocol/trade/UpdatePositionRequest";
import {UpdateEntryOrderRequest} from "protocol/trade/UpdateEntryOrderRequest";
import DealEditTarget from "enum/DealEditTarget";
import {SymbolsState} from "core/state/SymbolsState";
import WebUtil from "platform/util/WebUtil";
import {GetSymbolDetailsRequest} from "protocol/symbol/GetSymbolDetailsRequest";
import {SymbolDetails} from "protocol/symbol/GetSymbolDetailsResponse";
import {TranslationKey} from "enum/TranslationKey";
import ChartEngine from "core/engine/ChartEngine";
import {TradeUtil} from "core/util/TradeUtil";
import Translations from "platform/translation/Translations";
import {TranslationParam} from "enum/TranslationParam";
import {FetchIsRollingContractsEnabled} from "core/redux/settings/SettingsReduxActions";
import {ClosePositionsByExposureResponse} from "protocol/trade/ClosePositionsByExposureResponse";
import {ClosePositionsByExposureRequest} from "protocol/trade/ClosePositionsByExposureRequest";
import {SetFilterVisible} from "core/redux/filter/FilterReduxActions";
import {GetSymbolExpirationDateRequest} from "protocol/symbol/GetSymbolExpirationDateRequest";
import {GetSymbolExpirationDateResponse} from "protocol/symbol/GetSymbolExpirationDateResponse";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {NumberFormat} from "core/format/NumberFormat";
import {SymbolUtil} from "core/util/SymbolUtil";
import {SetClosedTradeDetail, SetOrderDetail} from "core/redux/trades/ClosedTradesReduxActions";
import {LocalizationKeys} from "enum/LocalizationKeys";
import {NonProtectedTradeDeclaration} from "enum/NonProtectedTradeDeclaration";
import {AcceptNonProtectedTradingRequest} from "protocol/account/AcceptNonProtectedTradingRequest";
import AccountEngine from "core/engine/AccountEngine";
import {BIEventType} from "enum/BIEventType";
import SignalsEngine from "core/engine/SignalsEngine";
import {SetSignalsSettings} from "core/redux/deal/SignalsReduxActions";
import {PositionOpenReasonType} from "enum/PositionOpenReasonType";
import {PositionCloseReasonType} from "enum/PositionCloseReasonType";
import {UpdateOCOEntryOrderRequest} from "protocol/trade/UpdateOCOEntryOrderRequest";
import {RouteType} from "core/router/Routes";
import {BIUtil} from "core/util/BIUtil";
import {OrderLimitRequest} from "protocol/trade/OrderLimitRequest";
import {BIDealOperationType} from "enum/BIDealOperationType";
import {QuotesState} from "core/state/QuotesState";
import {QuoteStatus} from "platform/protocol/enum/QuoteStatus";
import {DpkHandler} from "core/dpk/DpkHandler";
import {DpkType} from "enum/DpkType";
import {TradeManagerTab} from "enum/TradeManagerTab";
import {TSMap} from "typescript-map";
import {OrderType} from "platform/protocol/trading/OrderType";
import {ErrorCode} from "enum/ErrorCode";
import {OnProcessTopUpButton} from "core/redux/account/AccountReduxActions";
import {BIEventReferrer} from "enum/BIEventReferrer";
import {ClosePositionResponse} from "protocol/trade/ClosePositionResponse";
import {SkipAbleValidation} from "protocol/trade/SkippableValidation";
import {LoginAccountType} from "protocol/auth/LoginAccountType";
import {GetExtendedPositionRequest} from "protocol/trade/GetExtendedPositionRequest";
import {AppCuesManager} from "core/appCues/IAppCues";

export default class DealEngine extends Engine {

    private static _instance: DealEngine;
    private _TradeClosedListener: TSMap<number, () => void> = new TSMap<number, () => void>();

    public static instance(): DealEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        await SignalsEngine.instance().setup();
    }

    public addTradeClosedListener = (SymbolId: number, listener: () => void): () => void => {
        this._TradeClosedListener.set(SymbolId, listener);
        return (): void => {
            this._TradeClosedListener.delete(SymbolId);
        };
    }

    public onToggleMenu = (payload: ToggleMenuPayload) => {
        if (!payload.collapse) {
            const symbolsState: SymbolsState = Platform.state(ServiceType.Symbols);
            symbolsState.openTradeSymbolId = null;
            setTimeout(() => {
                Platform.dispatch(ClearDealFormRanges({}));
            }, 150);
        }
    }

    public doGoingOpenDeal = async (payload: GoingOpenDealPayload) => {
        const storeState: StoreState = Platform.reduxState<StoreState>();
        const {IsUserAllowedToTrade, ShouldShowKYC, Balance, ShouldShowInappropriatePopup} = storeState.account;
        const {isSetAlert} = payload;
        if (Balance > 0) {
            if (ShouldShowInappropriatePopup) {
                Platform.dispatch(SetTradingModal({
                    tradingModalType: TradingModalType.KYC,
                    info: {visible: true}
                }));
            } else if (ShouldShowKYC) {
                if (payload?.action) {
                    payload.action(RouteType.Kyc);
                } else {
                    const accountEngine: AccountEngine = Platform.engine<AccountEngine>(ServiceType.Account);
                    accountEngine.doOpenKyc();
                }
            } else if (isSetAlert || IsUserAllowedToTrade) {
                await this.doGoingOpenDealInternal(payload);
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.notAllowedToTradeError
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        } else {
            await this.doGoingOpenDealInternal(payload);
        }
    }

    public static GetSymbolDetails = async (SymbolId: number): Promise<SymbolDetails> => {
        const request: GetSymbolDetailsRequest = {SymbolId};
        const answer: [HttpReject, SymbolDetails] = await Utils.to(XhrUtil.sendToAccountService(request, "GetSymbolDetails"));
        return answer[1] || {};
    }

    public static GetSymbolExpirationDate = async (SymbolId: number): Promise<GetSymbolExpirationDateResponse> => {
        const request: GetSymbolExpirationDateRequest = {symbolId: SymbolId};
        const answer: [HttpReject, GetSymbolExpirationDateResponse] = await Utils.to(XhrUtil.sendToWebProfitClientService(request, "GetSymbolExpirationAndCloseOnlyDateTime"));
        return answer[1] || {};
    }

    private doGoingOpenDealInternal = async ({SymbolId, SignalId, tradeType, Direction, orderRate = null, stopLoss = null, takeProfit = null, comments = null, action}: GoingOpenDealPayload) => {
        const {auth, account, deal, settings} = Platform.reduxState<StoreState>();
        const {ShouldAcceptNonProtectedTradeDeclaration} = account;
        const OpenFn = async () => {
            const {form} = deal;
            if (Utils.isNull(form) || Utils.greaterThen0(form.PositionId) || form.SymbolId !== SymbolId || form.SignalId !== SignalId || form.PositionDirection !== Direction
                || form.OrderRate !== orderRate || form.StopLossValueRate !== stopLoss || form.TakeProfitValueRate !== takeProfit
                || (Utils.isNotNull(tradeType) && form.RateRequestType !== tradeType) || form.Comments !== comments) {
                this._logger.debug("Going open deal for symbol id: " + SymbolId);
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                Platform.dispatch(DealOpenEditInitialization({pending: true}));
                try {
                    const IsInvestAccount: boolean = auth.loginAccountType === LoginAccountType.Invest;
                    const symbolsState: SymbolsState = Platform.state(ServiceType.Symbols);
                    symbolsState.openTradeSymbolId = SymbolId;
                    const symbolDetails: SymbolDetails = await DealEngine.GetSymbolDetails(SymbolId);
                    const symbolExpirationDate: GetSymbolExpirationDateResponse = await DealEngine.GetSymbolExpirationDate(SymbolId);
                    Platform.dispatch(SetSymbolDetails({details: symbolDetails}));
                    Platform.dispatch(ClearDealFormRanges({}));
                    Platform.dispatch(UpdateDealForm({
                        form: {
                            SymbolId,
                            SignalId,
                            SymbolCloseOnlyDateTime: symbolExpirationDate.SymbolCloseOnlyDateTime,
                            SymbolExpirationDate: symbolExpirationDate.SymbolExpirationDate,
                            RateRequestType: symbolDetails.DisableEntryOrders ? TradeType.MarketOrder : IsInvestAccount ? TradeType.EntryOrder : tradeType || TradeType.MarketOrder,
                            PositionDirection: IsInvestAccount ? TradeDirection.Buy : Direction,
                            Amount: Math.min(Utils.nullTo0(symbolDetails.MinimalAmount), Utils.nullTo0(symbolDetails.Amounts?.[0])),
                            Amounts: TradeUtil.openDealAmounts(symbolDetails),
                            DisableEntryOrders: symbolDetails.DisableEntryOrders,
                            OrderRate: orderRate,
                            StopLossAutomatic: Utils.isNotNull(symbolDetails.AutomaticStopLossPercentage),
                            StopLossChecked: Utils.greaterThen0(stopLoss),
                            StopLossValueRate: Utils.greaterThen0(stopLoss) ? stopLoss : null,
                            TakeProfitAutomatic: Utils.isNotNull(symbolDetails.AutomaticTakeProfitPercentage),
                            TakeProfitChecked: Utils.greaterThen0(takeProfit),
                            TakeProfitValueRate: Utils.greaterThen0(takeProfit) ? takeProfit : null,
                            Comments: comments
                        }
                    }));
                    this._logger.debug("Going open deal ready. Has payload action: " + Utils.isNotNull(action));
                    if (action) {
                        action();
                    } else {
                        if (WebUtil.isMobile()) {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.CreateEditTrade,
                                info: {
                                    visible: true
                                }
                            }));
                        } else {
                            Platform.dispatch(SetClosedTradeDetail({trade: null}));
                            Platform.dispatch(SetOrderDetail({order: null}));
                            Platform.dispatch(SetFilterVisible({visible: false}));
                            Platform.dispatch(SetSignalsSettings({visible: false}));
                            Platform.dispatch(ToggleMenu({collapse: true}));
                        }
                    }
                    AppCuesManager.Identify();
                } catch (e) {
                    this._logger.debug("Failed init open trade process");
                }
                Platform.dispatch(DealOpenEditInitialization({pending: false}));
                Platform.dispatch(HideLoader({}));
            } else {
                this._logger.debug("Opening deal for symbol id: " + SymbolId + " already in progress");
                Platform.dispatch(SetClosedTradeDetail({trade: null}));
                Platform.dispatch(SetOrderDetail({order: null}));
            }
        };
        if (ShouldAcceptNonProtectedTradeDeclaration) {
            const localizationKey: LocalizationKeys = ShouldAcceptNonProtectedTradeDeclaration === NonProtectedTradeDeclaration.ShouldAcceptForAppropriate ?
                LocalizationKeys.WebProfit_Common_WebProfit_Common_NonProtectedTradingDeclaration_Appropriate : LocalizationKeys.WebProfit_Common_WebProfit_Common_NonProtectedTradingDeclaration_Inappropriate;
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.INFO,
                    title: {trKey: TranslationKey.warning},
                    clazz: "non-protected-trades-declaration",
                    message: {
                        customValue: settings.localization[localizationKey]
                    },
                    showClose: true,
                    actions: [{type: PopupActionType.OK, text: {trKey: TranslationKey.accept}, action: async () => {
                            this._logger.debug("Try accept non protected trades declaration");
                            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                            const request: AcceptNonProtectedTradingRequest = {ApplicationType: 6};
                            const answer: [HttpReject, boolean] = await Utils.to(XhrUtil.sendToWebProfitClientService(request, "AcceptNonProtectedTrading"));
                            Platform.dispatch(HideLoader({}));
                            if (answer[1]) {
                                await OpenFn();
                            }
                        }}]
                }
            }));
        } else {
            await OpenFn();
        }
    }

    public doGoingEditDeal = async (payload: GoingEditDealPayload) => {
        const {DealId, tradeType, dealEditTarget} = payload;
        const {auth, deal} = Platform.reduxState<StoreState>();
        const {form} = deal;
        if (Utils.isNull(form) || Utils.isNull(form.PositionId) || form.PositionId !== DealId
                || (!form.StopLossChecked && dealEditTarget === DealEditTarget.StopLoss) || (!form.TakeProfitChecked && dealEditTarget === DealEditTarget.TakeProfit)) {
            this._logger.debug("Going update deal with id: " + DealId + ". Trade type: " + tradeType + " Edit target: " + dealEditTarget);
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            Platform.dispatch(DealOpenEditInitialization({pending: true}));
            try {
                Platform.dispatch(FetchIsRollingContractsEnabled({}));
                const isMarketOrder: boolean = tradeType === TradeType.MarketOrder;
                const path: string = isMarketOrder ? "GetExtendedPositionv2" : "GetExtendedEntryOrderv2";
                const request: GetExtendedPositionRequest = {
                    EntryOrderId: isMarketOrder ? null : DealId,
                    PositionId: isMarketOrder ? DealId : null
                }
                const answer: [HttpReject, any] = await Utils.to(XhrUtil.sendToAccountService(request, path));
                if (answer[0]) {
                    XhrUtil.notifyReject(answer[0]);
                } else {
                    if (isMarketOrder) {
                        if (auth.loginAccountType !== LoginAccountType.Invest) {
                            Platform.dispatch(SetPositionTabDealId({DealId}));
                        }
                        const op: OpenPositionExtended = answer[1];
                        const symbolDetails: SymbolDetails = await DealEngine.GetSymbolDetails(op.SymbolId);
                        const symbolExpirationDate: GetSymbolExpirationDateResponse = await DealEngine.GetSymbolExpirationDate(op.SymbolId);
                        Platform.dispatch(SetSymbolDetails({details: symbolDetails}));
                        Platform.dispatch(ClearDealFormRanges({}));
                        Platform.dispatch(UpdateDealForm({
                            form: {
                                SymbolId: op.SymbolId,
                                SymbolCloseOnlyDateTime: symbolExpirationDate.SymbolCloseOnlyDateTime,
                                SymbolExpirationDate: symbolExpirationDate.SymbolExpirationDate,
                                Amount: op.Amount,
                                DisableEntryOrders: symbolDetails.DisableEntryOrders,
                                PositionDirection: op.PositionDirection,
                                PositionId: op.Id,
                                RateRequestType: TradeType.MarketOrder,
                                StopLossAutomatic: Utils.isNotNull(symbolDetails.AutomaticStopLossPercentage),
                                StopLossValueRateOrigin: Utils.greaterThen0(op.StopLossRate) ? op.StopLossRate : null,
                                StopLossValueRate: Utils.greaterThen0(op.StopLossRate) ? op.StopLossRate : null,
                                StopLossValueAmountOrigin: Utils.isNotNull(op.StopLossAmount) && op.HasUserEnteredStopLossAmount ? op.StopLossAmount : null,
                                StopLossValueAmount: Utils.isNotNull(op.StopLossAmount) && op.HasUserEnteredStopLossAmount ? op.StopLossAmount : null,
                                StopLossChecked: Utils.greaterThen0(op.StopLossRate) || dealEditTarget === DealEditTarget.StopLoss,
                                TakeProfitAutomatic: Utils.isNotNull(symbolDetails.AutomaticTakeProfitPercentage),
                                TakeProfitValueRateOrigin: Utils.greaterThen0(op.TakeProfitRate) ? op.TakeProfitRate : null,
                                TakeProfitValueRate: Utils.greaterThen0(op.TakeProfitRate) ? op.TakeProfitRate : null,
                                TakeProfitValueAmountOrigin: Utils.isNotNull(op.TakeProfitAmount) && op.HasUserEnteredTakeProfitAmount ? op.TakeProfitAmount : null,
                                TakeProfitValueAmount: Utils.isNotNull(op.TakeProfitAmount) && op.HasUserEnteredTakeProfitAmount ? op.TakeProfitAmount : null,
                                TakeProfitChecked: Utils.greaterThen0(op.TakeProfitRate) || dealEditTarget === DealEditTarget.TakeProfit,
                                HasUserEnteredStopLossAmount: op.HasUserEnteredStopLossAmount,
                                HasUserEnteredTakeProfitAmount: op.HasUserEnteredTakeProfitAmount,
                                RelatedOrder: Utils.isArrayNotEmpty(op.RelatedEntryOrders) ? op.RelatedEntryOrders[0] : null,
                                dealEditTarget
                            }
                        }));
                    } else {
                        const eo: EntryOrderExtended = answer[1];
                        let ExpirationDay: number;
                        let ExpirationMonth: number;
                        let ExpirationYear: number;
                        if (Utils.greaterThen0(eo.ExpirationDate)) {
                            const expirationDate: moment.Moment = DateTimeFormat.Of(eo.ExpirationDate);
                            ExpirationDay = expirationDate.date();
                            ExpirationMonth = expirationDate.month() + 1;
                            ExpirationYear = expirationDate.year();
                        }
                        const symbolDetails: SymbolDetails = await DealEngine.GetSymbolDetails(eo.SymbolId);
                        const symbolExpirationDate: GetSymbolExpirationDateResponse = await DealEngine.GetSymbolExpirationDate(eo.SymbolId);
                        Platform.dispatch(SetSymbolDetails({details: symbolDetails}));
                        Platform.dispatch(ClearDealFormRanges({}));
                        Platform.dispatch(UpdateDealForm({
                            form: {
                                SymbolId: eo.SymbolId,
                                SymbolCloseOnlyDateTime: symbolExpirationDate.SymbolCloseOnlyDateTime,
                                SymbolExpirationDate: symbolExpirationDate.SymbolExpirationDate,
                                Amount: eo.Amount,
                                Amounts: TradeUtil.openDealAmounts(symbolDetails),
                                DisableEntryOrders: symbolDetails.DisableEntryOrders,
                                OrderRateOrigin: eo.Rate,
                                OrderRate: eo.Rate,
                                PositionDirection: eo.PositionDirection,
                                PositionId: eo.Id,
                                OCOId: eo.OCOId,
                                OCORate: eo.OCORate,
                                OCOChecked: Utils.greaterThen0(eo.OCORate),
                                RateRequestType: TradeType.EntryOrder,
                                StopLossAutomatic: Utils.isNotNull(symbolDetails.AutomaticStopLossPercentage),
                                StopLossValueRateOrigin: Utils.greaterThen0(eo.StopLossRate) ? eo.StopLossRate : null,
                                StopLossValueRate: Utils.greaterThen0(eo.StopLossRate) ? eo.StopLossRate : null,
                                StopLossValueAmount: Utils.isNotNull(eo.StopLossAmount) && eo.HasUserEnteredStopLossAmount ? eo.StopLossAmount : null,
                                StopLossChecked: Utils.greaterThen0(eo.StopLossRate) || dealEditTarget === DealEditTarget.StopLoss,
                                TakeProfitAutomatic: Utils.isNotNull(symbolDetails.AutomaticTakeProfitPercentage),
                                TakeProfitValueRateOrigin: Utils.greaterThen0(eo.TakeProfitRate) ? eo.TakeProfitRate : null,
                                TakeProfitValueRate: Utils.greaterThen0(eo.TakeProfitRate) ? eo.TakeProfitRate : null,
                                TakeProfitValueAmount: Utils.isNotNull(eo.TakeProfitAmount) && eo.HasUserEnteredTakeProfitAmount ? eo.TakeProfitAmount : null,
                                TakeProfitChecked: Utils.greaterThen0(eo.TakeProfitRate) || dealEditTarget === DealEditTarget.TakeProfit,
                                ExpirationChecked: Utils.greaterThen0(eo.ExpirationDate),
                                HasUserEnteredStopLossAmount: eo.HasUserEnteredStopLossAmount,
                                HasUserEnteredTakeProfitAmount: eo.HasUserEnteredTakeProfitAmount,
                                RequiredMargin: eo.RequiredMargin,
                                dealEditTarget,
                                ExpirationDay,
                                ExpirationMonth,
                                ExpirationYear,
                            }
                        }));
                    }
                    this._logger.debug("Going edit deal ready. Has payload action: " + Utils.isNotNull(payload.action));
                    if (payload.action) {
                        payload.action();
                    } else {
                        if (WebUtil.isMobile()) {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.CreateEditTrade,
                                info: {
                                    visible: true
                                }
                            }));
                        } else {
                            Platform.dispatch(SetFilterVisible({visible: false}));
                            Platform.dispatch(SetSignalsSettings({visible: false}));
                            Platform.dispatch(ToggleMenu({collapse: true}));
                        }
                    }
                    AppCuesManager.Identify();
                }
            } catch (e) {
                this._logger.debug("Failed init edit trade process");
            }
            Platform.dispatch(DealOpenEditInitialization({pending: false}));
            Platform.dispatch(HideLoader({}));
        } else {
            this._logger.debug("Editing deal with ID: " + DealId + " already in progress");
        }
        if (Utils.isNull(payload.action) && !WebUtil.isMobile()) {
            Platform.dispatch(SetClosedTradeDetail({trade: null}));
            Platform.dispatch(SetOrderDetail({order: null}));
        }
    }

    public doOpenDeal = async (payload: OpenDealPayload) => {
        const {auth, deal, quotes} = Platform.reduxState<StoreState>();
        const form: DealForm = deal.form;
        if (form) {
            const accountState: AccountState = Platform.state(ServiceType.Account);
            const {
                RateRequestType,
                PositionDirection,
                SymbolId,
                SignalId,
                Amount,
                OrderRate,
                StopLossChecked,
                StopLossValueRate,
                StopLossValueAmount,
                TakeProfitChecked,
                TakeProfitValueRate,
                TakeProfitValueAmount,
                UsePositionInsurance,
                UseSpreadCashBack,
                Comments
            } = form;
            const quote: Quote = quotes.quotes.get(SymbolId);
            const isMarketOrder: boolean = RateRequestType === TradeType.MarketOrder;
            const LimitRequest: OrderLimitRequest = {
                StopLossAmount: StopLossChecked && Utils.isNull(StopLossValueRate) ? StopLossValueAmount : null,
                StopLossRate: StopLossChecked ? StopLossValueRate : null,
                TakeProfitAmount: TakeProfitChecked && Utils.isNull(TakeProfitValueRate) ? TakeProfitValueAmount : null,
                TakeProfitRate: TakeProfitChecked ? TakeProfitValueRate : null,
            };
            this._logger.debug("Ask to open deal for symbol: " + SymbolId + " Market order: " + isMarketOrder);
            if (isMarketOrder && auth.loginAccountType !== LoginAccountType.Invest) {
                const PositionPromotionTypes: PromotionType[] = [];
                if (UsePositionInsurance) {
                    PositionPromotionTypes.push(PromotionType.POSITION_INSURANCE);
                }
                if (UseSpreadCashBack) {
                    PositionPromotionTypes.push(PromotionType.SPREAD_CASHBACK);
                }
                const request: OpenDealRequest = {
                    ...LimitRequest,
                    SymbolId,
                    Direction: PositionDirection,
                    Rate: PositionDirection === TradeDirection.Sell  ? quote.Bid : quote.Ask,
                    Amount: Amount.toString(),
                    Comments,
                    ExpirationDate: null,
                    OwnerAccountId: accountState.accountId.toString(),
                    PositionPromotionTypes,
                    QuoteId: quote.Id,
                    SignalId,
                    PositionOpenReasonType: PositionOpenReasonType.UserRequest
                };
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                const answer: [HttpReject, OpenDealResponse] = await Utils.to(this.sendToTradeService(request, "OpenPosition"));
                Platform.dispatch(HideLoader({}));
                Platform.dispatch(SetDealFormInProgress({formInProgress: false}));
                if (answer[0]) {
                    this._logger.debug("Failed open position");
                    this.setRejectReason(answer[0], payload.actionInsufficientFunds);
                    BIUtil.DealTrack(BIEventType.TradeError, LimitRequest, {
                        Type: BIDealOperationType.Open,
                        ErrorMessage: XhrUtil.getServerRejectReason(answer[0])
                    });
                } else {
                    Platform.dispatch(SetDealServerError({error: null}));
                    const response: OpenDealResponse = answer[1];
                    let OpenRate: number = 0;
                    if (response.P3 === 1) { // if true this is Buy and openRate is in property P33
                        OpenRate = response.P33;
                    } else { // else this is Sell and openRate is in property P32
                        OpenRate = response.P32;
                    }
                    BIUtil.DealTrack(BIEventType.PositionOpened, LimitRequest, {
                        OpenRate,
                        SignalId,
                        AddedPromotion: Utils.isArrayNotEmpty(PositionPromotionTypes) ? "yes" : "no",
                    });
                    if (payload.action) {
                        payload.action();
                    } else if (WebUtil.isMobile()) {
                        Platform.dispatch(SetTradingModal({
                            tradingModalType: TradingModalType.CreateEditTrade,
                            info: {
                                visible: false
                            }
                        }));
                    } else {
                        Platform.dispatch(ToggleMenu({collapse: false}));
                    }
                }
            } else {
                const {ExpirationChecked, ExpirationDay, ExpirationMonth, ExpirationYear} = form;
                let ExpirationDate: string = null;
                if (ExpirationChecked) {
                    ExpirationDate = ExpirationYear + "-" + Utils.pad(ExpirationMonth) + "-" + Utils.pad(ExpirationDay);
                }
                const request: BookEntryOrderRequest = {
                    ...LimitRequest,
                    SymbolId,
                    Amount: Amount.toString(),
                    Comments,
                    EntryOrderType: auth.loginAccountType === LoginAccountType.Invest && isMarketOrder ? OrderType.Market : null,
                    ExpirationDate,
                    LimitOrderRate: (Utils.isNotNull(OrderRate) ? OrderRate : 0).toString(),
                    OwnerAccountId: accountState.accountId.toString(),
                    PositionDirection,
                    SignalId
                };
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                // "BookOCOEntryOrders" in case we need OCO orders
                const answer: [HttpReject, OpenDealResponse] = await Utils.to(this.sendToTradeService(request, "BookEntryOrder"));
                Platform.dispatch(HideLoader({}));
                Platform.dispatch(SetDealFormInProgress({formInProgress: false}));
                if (answer[0]) {
                    this._logger.debug("Failed book order");
                    this.setRejectReason(answer[0]);
                    BIUtil.DealTrack(BIEventType.TradeError, LimitRequest, {
                        Type: BIDealOperationType.Open,
                        ErrorMessage: XhrUtil.getServerRejectReason(answer[0]),
                        OrderRate,
                        ExpirationDate
                    });
                } else {
                    Platform.dispatch(SetDealServerError({error: null}));
                    if (payload.action) {
                        payload.action();
                    } else {
                        if (WebUtil.isMobile()) {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.CreateEditTrade,
                                info: {
                                    visible: false
                                }
                            }));
                        } else {
                            Platform.dispatch(ToggleMenu({collapse: false}));
                        }
                    }
                    BIUtil.DealTrack(BIEventType.OrderCreated, LimitRequest, {
                        OrderRate,
                        SignalId,
                        ExpirationDate
                    });
                }
            }
        } else {
            this._logger.debug("Can't open deal. Form absent");
        }
    }

    private setRejectReason = (error: HttpReject, actionInsufficientFunds?: (routeType: RouteType) => void): void =>{
        const sError: string = XhrUtil.getRejectReason(error);
        Platform.dispatch(SetDealServerError({error: sError}));
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.ERROR,
                message: {
                    customValue: sError
                },
                showClose: true,
                icon: {type: PopupIconType.ERROR},
                actions: [{
                    type: PopupActionType.OK, action: () => {
                        const isNotEnoughFunds: boolean = XhrUtil.getServerErrorCode(error) === ErrorCode.NotEnoughFunds;
                        const {dynamicConfiguration} = Platform.reduxState<StoreState>().app;
                        if (isNotEnoughFunds && dynamicConfiguration?.DepositOnInsufficientFunds) {
                            Platform.dispatch(OnProcessTopUpButton({referrer: BIEventReferrer.External, action: actionInsufficientFunds}));
                        }
                    }
                }]
            }
        }));
    }

    public doEditDeal = async (payload: EditDealPayload) => {
        const {deal, trades} = Platform.reduxState<StoreState>();
        const form: DealForm = deal.form;
        const {RateRequestType, PositionId, Amount, OrderRate, StopLossChecked, StopLossValueRate, StopLossValueAmount,
            TakeProfitChecked, TakeProfitValueRate, TakeProfitValueAmount, HasUserEnteredStopLossAmount, HasUserEnteredTakeProfitAmount} = form;
        const isMarketOrder: boolean = RateRequestType === TradeType.MarketOrder;
        const {ExpirationChecked, ExpirationDay, ExpirationMonth, ExpirationYear} = form;
        let ExpirationDate: string = null;
        if (ExpirationChecked) {
            ExpirationDate = ExpirationYear + "-" + Utils.pad(ExpirationMonth) + "-" + Utils.pad(ExpirationDay);
        }
        const updateForm: DealForm = {};
        if (!StopLossChecked) {
            updateForm.StopLossValueRate = null;
            updateForm.StopLossValueAmount = null;
            updateForm.HasUserEnteredStopLossAmount = false;
        }
        if (!TakeProfitChecked) {
            updateForm.TakeProfitValueRate = null;
            updateForm.TakeProfitValueAmount = null;
            updateForm.HasUserEnteredTakeProfitAmount = false;
        }
        const LimitRequest: OrderLimitRequest = {
            StopLossAmount: StopLossChecked && (HasUserEnteredStopLossAmount || Utils.isNull(StopLossValueRate)) ? StopLossValueAmount : null,
            StopLossRate: StopLossChecked && !HasUserEnteredStopLossAmount ? StopLossValueRate : null,
            TakeProfitAmount: TakeProfitChecked && (HasUserEnteredTakeProfitAmount || Utils.isNull(TakeProfitValueRate)) ? TakeProfitValueAmount : null,
            TakeProfitRate: TakeProfitChecked && !HasUserEnteredTakeProfitAmount ? TakeProfitValueRate : null
        };
        this._logger.debug("Ask to edit deal: " + PositionId + " Market order: " + isMarketOrder);
        if (isMarketOrder) {
            const request: UpdatePositionRequest = {
                ...LimitRequest,
                PositionId,
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, OpenDealResponse] = await Utils.to(this.sendToTradeService(request, "UpdatePosition"));
            Platform.dispatch(HideLoader({}));
            Platform.dispatch(SetDealFormInProgress({formInProgress: false}));
            const openPosition: Deal = trades.OpenPositionsMap[PositionId];
            const BIData = {
                PositionID: openPosition?.Id,
                OpenRate: openPosition?.Rate,
                MarketRate: openPosition?.MarketRate,
                PL: openPosition?.PL,
                CreatedDate: DateTimeFormat.formatDateTime(openPosition?.Date)
            };
            if (answer[0]) {
                this._logger.debug("Failed edit position");
                this.setRejectReason(answer[0]);
                BIUtil.DealTrack(BIEventType.TradeError, LimitRequest, {
                    Type: BIDealOperationType.Update,
                    ErrorMessage: XhrUtil.getServerRejectReason(answer[0]),
                    ...BIData
                });
            } else {
                if (!Utils.isObjectEmpty(updateForm)) {
                    Platform.dispatch(UpdateDealForm({form: updateForm}));
                }
                const chartEngine: ChartEngine = Platform.engine(ServiceType.Chart);
                chartEngine.doUpdateDeal(PositionId, answer[1]);
                Platform.dispatch(SetDealServerError({error: null}));
                Platform.dispatch(SetTradingModal({
                    tradingModalType: TradingModalType.CreateEditTrade,
                    info: {
                        visible: false
                    }
                }));
                if (payload?.callback) {
                    payload.callback(answer[1].P1);
                }
                BIUtil.DealTrack(BIEventType.PositionUpdated, LimitRequest, {
                    ...BIData
                });
            }
        } else {
            const {OCOId, OCOChecked, OCORate} = form;
            let answer: [HttpReject, OpenDealResponse];
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            if (Utils.greaterThen0(OCOId)) {
                const request: UpdateOCOEntryOrderRequest = {
                    Amount,
                    OCOEntryOrderId: OCOId,
                    ExpirationDate,
                    LimitRate: OCOChecked ? OCORate : null,
                    StopRate: OrderRate
                };
                answer = await Utils.to(this.sendToTradeService(request, "UpdateOCOEntryOrders"));
            } else {
                const request: UpdateEntryOrderRequest = {
                    ...LimitRequest,
                    Amount,
                    ExpirationDate,
                    EntryOrderId: PositionId,
                    EntryOrderType: null,
                    LimitOrderRate: OrderRate
                };
                answer = await Utils.to(this.sendToTradeService(request, "UpdateEntryOrder"));
            }
            Platform.dispatch(HideLoader({}));
            Platform.dispatch(SetDealFormInProgress({formInProgress: false}));
            const entryOrder: Deal = trades.EntryOrdersMap[PositionId];
            const BIData = {
                EntryOrderID: entryOrder?.Id,
                OpenRate: entryOrder?.Rate,
                MarketRate: entryOrder?.MarketRate,
                ExpirationDate,
                CreatedDate: DateTimeFormat.formatDateTime(entryOrder?.Date)
            };
            if (answer[0]) {
                this._logger.debug("Failed update order");
                this.setRejectReason(answer[0]);
                BIUtil.DealTrack(BIEventType.TradeError, LimitRequest, {
                    Type: BIDealOperationType.Update,
                    ErrorMessage: XhrUtil.getServerRejectReason(answer[0]),
                    ...BIData
                });
            } else {
                if (!OCOChecked) {
                    updateForm.OCORate = null;
                }
                if (!Utils.isObjectEmpty(updateForm)) {
                    Platform.dispatch(UpdateDealForm({form: updateForm}));
                }
                Platform.dispatch(SetDealServerError({error: null}));
                Platform.dispatch(SetTradingModal({
                    tradingModalType: TradingModalType.CreateEditTrade,
                    info: {
                        visible: false
                    }
                }));
                if (payload?.callback) {
                    payload.callback(answer[1].P1);
                }
                BIUtil.DealTrack(BIEventType.OrderUpdated, LimitRequest, {
                    ...BIData
                });
            }
        }
    }

    public doEditDealFromChart = async (DealId: number, StopLoss: number, TakeProfit: number): Promise<OpenDealResponse> => {
        const {OpenPositionsMap} = Platform.reduxState<StoreState>().trades;
        const deal: Deal = OpenPositionsMap[DealId];
        if (deal) {
            this._logger.debug("Start to update deal from chart " + DealId + " SL: " + StopLoss + " TP " + TakeProfit);
            const quotesState: QuotesState = Platform.state(ServiceType.Quotes);
            const quote: Quote = quotesState.getQuote(deal.SymbolId);
            if (Utils.isNull(quote) || quote.Status === QuoteStatus.Tradable) {
                const symbol: TradeSymbol = Platform.reduxState<StoreState>().symbols.symbols.get(deal.SymbolId);
                const LimitRequest: OrderLimitRequest = {
                    StopLossAmount: null,
                    StopLossRate: NumberFormat.round(StopLoss, SymbolUtil.decimals(symbol)),
                    TakeProfitAmount: null,
                    TakeProfitRate: NumberFormat.round(TakeProfit, SymbolUtil.decimals(symbol))
                };
                const request: UpdatePositionRequest = {
                    ...LimitRequest,
                    PositionId: DealId,
                };
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                const answer: [HttpReject, OpenDealResponse] = await Utils.to(this.sendToTradeService(request, "UpdatePosition"));
                Platform.dispatch(HideLoader({}));
                const BIData = {
                    PositionID: deal?.Id,
                    OpenRate: deal?.Rate,
                    MarketRate: deal?.MarketRate,
                    PL: deal?.PL,
                    CreatedDate: DateTimeFormat.formatDateTime(deal?.Date)
                };
                if (answer[0]) {
                    this._logger.debug("Failed edit deal from chart");
                    XhrUtil.notifyReject(answer[0]);
                    BIUtil.DealTrack(BIEventType.TradeError, LimitRequest, {
                        Type: BIDealOperationType.Update,
                        ErrorMessage: XhrUtil.getServerRejectReason(answer[0]),
                        ...BIData
                    });
                } else {
                    this._logger.debug("Successful edit deal from chart");
                    const {form} = Platform.reduxState<StoreState>().deal;
                    if (form && (form.StopLossChecked || form.TakeProfitChecked)) {
                        const updatedDeal: Deal = TradeUtil.mapResponseToDeal(answer[1]);
                        Platform.dispatch(UpdateDealForm({
                            form: {
                                StopLossValueAmount: null,
                                StopLossValueRate: updatedDeal.StopLossRate,
                                TakeProfitValueAmount: null,
                                TakeProfitValueRate: updatedDeal.TakeProfitRate,
                                HasUserEnteredStopLossAmount: false,
                                HasUserEnteredTakeProfitAmount: false,
                            }
                        }));
                        const chartEngine: ChartEngine = Platform.engine(ServiceType.Chart);
                        chartEngine.doUpdateDeal(updatedDeal.Id, answer[1]);
                    }
                    Platform.dispatch(SetDealServerError({error: null}));
                    BIUtil.DealTrack(BIEventType.PositionUpdated, LimitRequest, {
                        ...BIData
                    });
                    return Promise.resolve(answer[1]);
                }
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        icon: {type: PopupIconType.ERROR},
                        message: {
                            trKey: TranslationKey.errorTradingHours
                        },
                        actions: [
                            {
                                type: PopupActionType.OK,
                                action: () => {
                                    Platform.dispatch(GoingOpenDeal({
                                        SymbolId: deal.SymbolId,
                                        Direction: PositionDirection.Sell,
                                        action: DpkHandler.GetAction(DpkType.OpenPosition)
                                    }));
                                    Platform.dispatch(SetTradeManagerTab({tab: TradeManagerTab.Info}));
                                }
                            },
                            {type: PopupActionType.CANCEL}
                        ]
                    }
                }));
            }
        } else {
            this._logger.debug("Can't update deal from chart. Deal not found: " + DealId);
        }
        return Promise.reject(false);
    }

    public doCloseDeals = async (payload: CloseDealsPayload) => {
        const {iDs, tradeType, modalType, ValidationsToSkip, callback} = payload;
        const {trades} = Platform.reduxState<StoreState>();
        if (Utils.isArrayNotEmpty(iDs)) {
            this._logger.debug("Try to close deals " + iDs);
            const notify = (): void => {
                Platform.dispatch(SetTradingModal({
                    tradingModalType: modalType,
                    info: {
                        visible: false
                    }
                }));
            };
            const isMarketOrder: boolean = tradeType === TradeType.MarketOrder;
            if (iDs.length > 1) {
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                const deals: Deal[] = [];
                const dealsMap: {[key: number]: Deal} = isMarketOrder ? trades.OpenPositionsMap : trades.EntryOrdersMap;
                iDs.forEach((id: number) => {
                    const deal: Deal = dealsMap[id];
                    if (deal) {
                        deals.push(deal);
                    }
                });
                const request: CloseMultiplePositions = {
                    PositionIds: isMarketOrder ? iDs : null,
                    EntryOrderIds: isMarketOrder ? null : iDs,
                    PositionCloseReasonType: PositionCloseReasonType.UserRequest,
                    ValidationsToSkip
                };
                const path: string = isMarketOrder ? "CloseMultiplePositions" : "CancelMultipleEntryOrders";
                const answer: [HttpReject, CloseMultiplePositionsResponse] = await Utils.to(this.sendToTradeService(request, path));
                Platform.dispatch(HideLoader({}));
                Platform.dispatch(SetDealFormInProgress({formInProgress: false}));
                if (answer[0]) {
                    this.doProcessCloseDealFailure(answer, payload);
                    const ErrorMessage: string = XhrUtil.getServerRejectReason(answer[0]);
                    deals.forEach((deal: Deal) => {
                        BIUtil.DealCloseTrack(BIEventType.TradeError, deal, isMarketOrder, {
                            Type: BIDealOperationType.Close,
                            ErrorMessage
                        });
                    });
                } else {
                    Platform.dispatch(SetDealServerError({error: null}));
                    Platform.dispatch(SetTradingModal({
                        tradingModalType: modalType,
                        info: {
                            visible: false
                        }
                    }));
                    const response: CloseMultiplePositionsResponse = answer[1];
                    if (Utils.isArrayNotEmpty(response.PositionsThatClosedSuccessfully)) {
                        const {form} = Platform.reduxState<StoreState>().deal;
                        response.PositionsThatClosedSuccessfully.forEach((positionThatClosedSuccessfully: OpenDealResponse) => {
                            const id: number = positionThatClosedSuccessfully.P1;
                            if (Utils.greaterThen0(form?.PositionId) && form?.PositionId === id) {
                                Platform.dispatch(ToggleMenu({collapse: false}));
                                Platform.dispatch(SetTradingModal({
                                    tradingModalType: TradingModalType.CreateEditTrade,
                                    info: {
                                        visible: false
                                    }
                                }));
                            }
                            const deal: Deal = deals.filter((d: Deal) => d.Id === id)[0];
                            if (deal) {
                                notify();
                                const eventType: BIEventType = isMarketOrder ? BIEventType.PositionClosed : BIEventType.OrderCancelled;
                                BIUtil.DealCloseTrack(eventType, deal, isMarketOrder, {});
                            }
                        });
                    }
                    if (Utils.isArrayNotEmpty(response.PositionsThatFailedToClose)) {
                        const description: string[] = [Translations.text(TranslationKey.failedClosePositionDescription)];
                        response.PositionsThatFailedToClose.forEach((positionThatFailedToClose: PositionThatFailedToClose) => {
                            description.push(
                                Translations.text(TranslationKey.failedClosePosition,
                                    Parameter.Of(TranslationParam.name, positionThatFailedToClose.Item1),
                                    Parameter.Of(TranslationParam.value, positionThatFailedToClose.Item2))
                            );
                            const deal: Deal = deals.filter((d: Deal) => d.Id === positionThatFailedToClose.Item1)[0];
                            BIUtil.DealCloseTrack(BIEventType.TradeError, deal, isMarketOrder, {
                                Type: BIDealOperationType.Close,
                                ErrorMessage: positionThatFailedToClose.Item2
                            });
                        });
                        Platform.dispatch(ShowPopup({
                            popup: {
                                type: PopupType.ERROR,
                                message: {
                                    customValue: description.join("<br />")
                                },
                                showClose: true,
                                icon: {type: PopupIconType.ERROR},
                                actions: [{type: PopupActionType.OK}]
                            }
                        }));
                    }
                    if (callback) {
                        callback();
                    }
                }
            } else {
                if (isMarketOrder) {
                    const deal: Deal = trades.OpenPositionsMap[iDs[0]];
                    if (deal) {
                        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                        const request: ClosePosition = {
                            PositionId: deal.Id,
                            QuoteId: deal.QuoteId,
                            Rate: deal.MarketRate,
                            PositionCloseReasonType: PositionCloseReasonType.UserRequest,
                            ValidationsToSkip
                        };
                        const answer: [HttpReject, ClosePositionResponse] = await Utils.to(this.sendToTradeService(request, "ClosePosition"));
                        Platform.dispatch(HideLoader({}));
                        Platform.dispatch(SetDealFormInProgress({formInProgress: false}));
                        if (answer[0]) {
                            this.doProcessCloseDealFailure(answer, payload);
                            BIUtil.DealCloseTrack(BIEventType.TradeError, deal, isMarketOrder, {
                                Type: BIDealOperationType.Close,
                                ErrorMessage: XhrUtil.getServerRejectReason(answer[0]),
                            });
                        } else {
                            const {form} = Platform.reduxState<StoreState>().deal;
                            if (Utils.greaterThen0(form?.PositionId) && form?.PositionId === deal.Id) {
                                Platform.dispatch(GoingOpenDeal({
                                    SymbolId: deal.SymbolId,
                                    Direction: PositionDirection.Sell,
                                }));
                                Platform.dispatch(SetTradeManagerTab({tab: TradeManagerTab.Positions}));
                            }
                            Platform.dispatch(SetDealServerError({error: null}));
                            notify();
                            if (callback) {
                                callback();
                            }
                            const listener: () => void = this._TradeClosedListener.get(deal.SymbolId);
                            if (listener) {
                                listener();
                            }
                            BIUtil.DealCloseTrack(BIEventType.PositionClosed, deal, isMarketOrder, {});
                        }
                    } else {
                        this._logger.debug("Can't find deal to close with id: " + iDs[0]);
                    }
                } else {
                    const order: Deal = trades.EntryOrdersMap[iDs[0]];
                    if (order) {
                        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                        const request: CancelEntryOrder = {
                            EntryOrderId: order.Id
                        };
                        const answer: [HttpReject, BaseResponse] = await Utils.to(this.sendToTradeService(request, "CancelEntryOrder"));
                        Platform.dispatch(HideLoader({}));
                        Platform.dispatch(SetDealFormInProgress({formInProgress: false}));
                        if (answer[0]) {
                            this.setRejectReason(answer[0]);
                            BIUtil.DealCloseTrack(BIEventType.TradeError, order, isMarketOrder, {
                                Type: BIDealOperationType.Close,
                                ErrorMessage: XhrUtil.getServerRejectReason(answer[0]),
                            });
                        } else {
                            const {form} = Platform.reduxState<StoreState>().deal;
                            if (Utils.greaterThen0(form?.PositionId) && form?.PositionId === order.Id) {
                                Platform.dispatch(ToggleMenu({collapse: false}));
                                Platform.dispatch(SetTradingModal({
                                    tradingModalType: TradingModalType.CreateEditTrade,
                                    info: {
                                        visible: false
                                    }
                                }));
                            }
                            Platform.dispatch(SetDealServerError({error: null}));
                            notify();
                            if (callback) {
                                callback();
                            }
                            BIUtil.DealCloseTrack(BIEventType.OrderCancelled, order, isMarketOrder, {});
                        }
                    } else {
                        this._logger.debug("Can't find entry order to close with id: " + iDs[0]);
                    }
                }
            }
        } else {
            this._logger.debug("Can't close deals. Ids array empty");
        }
    }

    private doProcessCloseDealFailure = (answer: [HttpReject, ClosePositionResponse], payload: CloseDealsPayload): void => {
        const sResponse: string = answer[0].response;
        let hasEmtValidation: boolean;
        let eResponse: ClosePositionResponse;
        try {
            eResponse = JSON.parse(sResponse);
            if (Utils.isArrayNotEmpty(eResponse?.Validations)) {
                hasEmtValidation = Utils.isNotNull(eResponse.Validations.filter(v => v === SkipAbleValidation.EMTPercentage)[0]);
            }
        } catch (e) {
            this._logger.warn(`Failed parse close position response: ${sResponse}`);
        }
        if (hasEmtValidation) {
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.CONFIRM,
                    message: {
                        customValue: eResponse?.LocalizeMessage || eResponse?.Message
                    },
                    showClose: true,
                    icon: {type: PopupIconType.CONFIRM},
                    actions: [
                        {type: PopupActionType.CANCEL},
                        {type: PopupActionType.OK, text: {trKey: TranslationKey.closeAnyway}, action: () => {
                                const {iDs, tradeType, modalType, callback} = payload;
                                Platform.dispatch(CloseDeals({
                                    iDs,
                                    tradeType,
                                    modalType,
                                    ValidationsToSkip: eResponse.Validations,
                                    callback
                                }));
                            }
                        }]
                }
            }));
        } else {
            this.setRejectReason(answer[0]);
        }
    }

    public doRollPositions = async ({SymbolId, symbol, nextSymbol}: RollPositionsPayload) => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: ClosePositionsByExposureRequest = {
            SymbolId,
            AccountId: accountState.accountId,
            IsRolling: true,
            PositionDirections: null,
            PositionCloseReasonType: PositionCloseReasonType.UserRequest
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, ClosePositionsByExposureResponse] = await Utils.to(this.sendToTradeService(request, "ClosePositionsByExposure"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this.setRejectReason(answer[0]);
        } else {
            const response: ClosePositionsByExposureResponse = answer[1];
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.INFO,
                    message: {
                        trKey: response.RollingSuccess ? TranslationKey.tradeSymbolNotificationRollSuccess : TranslationKey.tradeSymbolNotificationRollFailed,
                        params: [
                            Parameter.Of(TranslationParam.name, symbol),
                            Parameter.Of(TranslationParam.value, nextSymbol)
                        ]
                    },
                    showClose: true,
                    icon: {type: PopupIconType.INFO},
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        }
    }

    private sendToTradeService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "TradeServer/TradeService.svc/json/" + path);
    }
}
