import moment, {Moment, MomentInputObject} from "moment-mini";
import Utils, {UNDEFINED_VALUE} from "platform/util/Utils";
import DatePart from "enum/DatePart";
import {TSMap} from "typescript-map";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {DayOfWeek} from "enum/DayOfWeek";
import {Locale} from "core/format/Locale";
import {LocaleConfig} from "core/configuration/Configuration";

export default class DateTimeFormat {

    private static DATE_FORMAT: string;
    private static DATE_TIME_FORMAT: string;
    private static DATE_TIME_SS_FORMAT: string;

    private constructor() {
    }

    public static dateFormat(): string {
        if (Utils.isNull(this.DATE_FORMAT)) {
            const format: string = Locale.provider.config().formats.dates.dateFormats.yMMMd;
            this.DATE_FORMAT = format.toUpperCase();
        }
        return this.DATE_FORMAT;
    }

    public static dateFormatInput(date: Moment): string {
        return date.format("YYYY-MM-DD");
    }

    public static convertDateForChat = (date: number, locale: LocaleConfig): string => {
        const newDate = new Date(date);
        const day = new Date(date).getDate();
        const weekDays: string[] = locale.formats.calendar.daysLong;
        const monthNames: string[] = locale.formats.calendar.months;

        return `${day} ${monthNames[newDate.getMonth()]}, ${weekDays[newDate.getDay()]}`;
    }

    public static convertData(date: number, separator: string = " "): string {
        const momentDate = this.Of(date);
        return momentDate.format(`DD/MM/YYYY${separator}h:mm:ss A`);
    }

    public static eduNewsData(value: string): string {
        const momentDate = moment(value);
        return momentDate.format(`DD.MM.YYYY hh:mm:ss`);
    }

    public static getLocaleTime(time: string, day: number, locale: LocaleConfig): {dayOfWeek: string, hour: string} {
        const hour: number = Number(time.split(':')[0]);
        const minute: string = time.split(':')[1];
        const weekDays: string[] = locale.formats.calendar.days;

        const date = new Date();
        let localHour = hour - (date.getTimezoneOffset() / 60);
        let localMinute = (localHour % 1) * 60;

        localHour = Math.floor(localHour);
        localMinute += parseInt(minute, 10);

        if (localMinute >= 60) {
            localHour += Math.floor(localMinute / 60);
            localMinute %= 60;
        }

        const getWeekday = (): number => {
            if (localHour >= 24) {
                return (day === weekDays.length - 1) ? 0 : day + 1;
            } else if (localHour < 0) {
                return (day === 0) ? weekDays.length - 1 : day - 1;
            } else {
                return day;
            }
        }

        const dayOfWeek: string = weekDays[getWeekday()];
        localHour %= 24;
        const tradingTime = `${localHour >= 0 ? localHour : 24 + localHour}:${localMinute < 10 ? `0${localMinute}` : localMinute}`;

        return {dayOfWeek, hour: tradingTime};
    }

    public static dateUniformFormat(date: number | moment.Moment | string | Date): string {
        return this.format(date, 'DD/MM/YYYY');
    }

    public static getLocalDate = (date: Date) => {
        const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return moment(newDate).format("DD/MM/YYYY HH:mm");
    }

    public static getLocalDateA = (date: Date) => {
        const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return moment(newDate).format("DD/MM/YYYY hh:mm A");
    }

    public static getTimeFormat = (date: number | moment.Moment | string | Date) => {
        return this.format(date, "hh:mm a");
    }

    public static getOpenDate (date: number) {
        return this.format(date, 'MM/DD/YYYY hh:mm A');
    }

    public static getPositionDate (date: number) {
        return this.format(date, 'DD/MM/YYYY');
    }

    public static timeFormat(): string {
        return Locale.provider.config().formats.dates.timeFormats.hm;
    }

    public static timeSSFormat(): string {
        return Locale.provider.config().formats.dates.timeFormats.hms;
    }

    public static dayOfWeek(value: number | moment.Moment | string | Date): string {
        const dayOfWeek = moment(value).isoWeekday();
        return Object.values(DayOfWeek)[dayOfWeek] || "";
    }

    public static dateTimeFormat(): string {
        if (Utils.isNull(this.DATE_TIME_FORMAT)) {
            const dates = Locale.provider.config().formats.dates;
            this.DATE_TIME_FORMAT = (dates.dateFormats.yMMMd).toUpperCase() + " HH:mm";
        }
        return this.DATE_TIME_FORMAT;
    }

    public static eduCalendarDateForma(date: string) {
        const newDate = moment(date, "YYYY-M-DD");
        return this.format(newDate, 'dddd, MMMM DD');
    }

    public static dateTimeSSFormat(): string {
        if (Utils.isNull(this.DATE_TIME_SS_FORMAT)) {
            const dates = Locale.provider.config().formats.dates;
            this.DATE_TIME_SS_FORMAT = (dates.dateFormats.yMMMd).toUpperCase() + " HH:mm:ss";
        }
        return this.DATE_TIME_SS_FORMAT;
    }

    public static dateTimeMillisFormat(): string {
        const dates = Locale.provider.config().formats.dates;
        return dates.dateFormats.yMd + " HH:mm:ss.SSS";
    }

    private static serverFormat(): string {
        return "YYYY-MM-DDTHH:mm:ss";
    }

    public static now(): moment.Moment {
        return moment();
    }

    public static ServerToLocal(date: string): Moment {
        return moment.utc(date).local();
    }

    public static FormatServerToLocal(date: string): string {
        const v: Moment = this.ServerToLocal(date);
        return v.format("DD/MM/YYYY hh:mm");
    }

    public static FormatDayOfWeekServerToLocal(date: string): string {
        const v: Moment = this.ServerToLocal(date);
        return `${this.dayOfWeek(v)} ${v.format("DD/MM/YYYY hh:mm")}`
    }

    public static Of(value: number | MomentInputObject): moment.Moment {
        return moment(value);
    }

    public static compose(datePart: moment.Moment, timePart?: moment.Moment): moment.Moment {
        const momentInfo: MomentInputObject = {year: 0, month: 0, day: 0, hour: 0, minute: 0, second: 0};
        if (Utils.isNotNull(datePart)) {
            momentInfo.year = datePart.year();
            momentInfo.month = datePart.month();
            momentInfo.day = datePart.date();
        }
        if (Utils.isNotNull(timePart)) {
            momentInfo.hour = timePart.hour();
            momentInfo.minute = timePart.minute();
        }
        return moment(momentInfo);
    }

    public static greaterThenNow(value: moment.Moment): boolean {
        if (Utils.isNotNull(value)) {
            return value.toDate().getTime() > this.now().toDate().getTime();
        }
        return false;
    }

    public static clone(value: moment.Moment): moment.Moment {
        return moment(value);
    }

    public static timestamp(value: moment.Moment): number {
        if (Utils.isNotNull(value)) {
            return parseInt(value.format("x"), 10);
        }
        return null;
    }

    public static compare(v1: moment.Moment, v2: moment.Moment): number {
        return Utils.compareNumber(this.timestamp(v1), this.timestamp(v2));
    }

    public static formatChartDate(value: number): string {
        return moment(new Date(value)).format("DD/MM");
    }

    public static formatChartTooltipDate(value: number | moment.Moment): string {
        return moment(value).format("MMM D, YYYY");
    }

    public static formatServerDate(value: number | moment.Moment | string | Date): string {
        return this.format(value, this.serverFormat());
    }

    public static formatDate(value: number | moment.Moment | string | Date): string {
        return this.format(value, this.dateFormat());
    }

    public static formatTime(value: number | moment.Moment | string | Date): string {
        return this.format(value, this.timeFormat());
    }

    public static formatDateTime(value: number | moment.Moment | string | Date): string {
        return this.format(value, this.dateTimeFormat());
    }

    public static formatDateTimeSS(value: number | moment.Moment | string | Date): string {
        return this.format(value, this.dateTimeSSFormat());
    }

    public static formatDateTimeMillis(value: number | moment.Moment | string | Date): string {
        return this.format(value, this.dateTimeMillisFormat());
    }

    public static parseTime(value: string): moment.Moment {
        if (Utils.isNotNull(value)) {
            return moment(value, this.timeFormat());
        }
        return null;
    }

    public static parse(value: string): moment.Moment {
        if (Utils.isNotNull(value)) {
            return moment(value, this.dateTimeSSFormat());
        }
        return null;
    }

    public static parseServer(value: string): moment.Moment {
        if (Utils.isNotNull(value)) {
            return moment(value, this.serverFormat());
        }
        return null;
    }

    public static getRangeInDays(startDate: number, endDate: number): number {
        const diff = Math.abs(startDate - endDate);
        return Math.round(diff / (3600000 * 24));
    }

    public static getDaysInCurrentMonth(): number {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }

    private static format(value: number | moment.Moment | string | Date, pattern: string): string {
        let v: number | moment.Moment | string | Date = value;
        if (Utils.isNotNull(v)) {
            if (typeof v === "string") {
                if (isNaN(Number(v))) {
                    return this.parse(value as string).format(pattern);
                } else {
                    v = Number(v);
                }
            }
            return moment(v).format(pattern);
        }
        return UNDEFINED_VALUE;
    }

    public static dateParts(value: number): TSMap<DatePart, number> {
        const dateParts: TSMap<DatePart, number> = new TSMap<DatePart, number>();
        if (value > 0) {
            const fromMoment: any = this.now();
            const toMoment: any = this.Of(this.timestamp(fromMoment) + value);
            this.putAndAddDiff(fromMoment, toMoment, DatePart.days, dateParts);
            this.putAndAddDiff(fromMoment, toMoment, DatePart.hours, dateParts);
            this.putAndAddDiff(fromMoment, toMoment, DatePart.minutes, dateParts);
            this.putAndAddDiff(fromMoment, toMoment, DatePart.seconds, dateParts);
        }
        return dateParts;
    }

    private static putAndAddDiff(fromMoment: any, toMoment: any, datePart: DatePart, dateParts: TSMap<DatePart, number>): void {
        let diff: number = toMoment.diff(fromMoment, datePart);
        if (diff > 0) {
            fromMoment.add(diff, datePart);
        } else {
            diff = 0;
        }
        dateParts.set(datePart, diff);
    }

    public static formatDateParts(value: number): string {
        const dateParts: TSMap<DatePart, number> = this.dateParts(value);
        const days: number = dateParts.get(DatePart.days);
        const hours: number = dateParts.get(DatePart.hours);
        const minutes: number = dateParts.get(DatePart.minutes);
        const seconds: number = dateParts.get(DatePart.seconds);
        const result: string[] = [];
        if (days > 0) {
            result.push(days.toString(), Translations.text(days > 1 ? TranslationKey.days : TranslationKey.day));
        }
        if (hours > 0) {
            result.push(hours.toString(), Translations.text(hours > 1 ? TranslationKey.hours : TranslationKey.hour));
        }
        if (minutes > 0) {
            result.push(minutes.toString(), Translations.text(minutes > 1 ? TranslationKey.minutes : TranslationKey.minute));
        }
        if (seconds > 0) {
            result.push(seconds.toString(), Translations.text(seconds > 1 ? TranslationKey.seconds : TranslationKey.second));
        }
        return result.join(" ");
    }

    public static formatMarketDateParts(value: number): string {
        const dateParts: TSMap<DatePart, number> = this.dateParts(value);
        const days: number = dateParts.get(DatePart.days);
        const hours: number = dateParts.get(DatePart.hours);
        let minutes: number = dateParts.get(DatePart.minutes);
        const seconds: number = dateParts.get(DatePart.seconds);
        if (seconds > 0) {
            minutes++;
        }
        const result: string[] = [];
        if (days > 0) {
            result.push(days.toString(), Translations.text(days > 1 ? TranslationKey.days : TranslationKey.day));
        }
        if (hours > 0) {
            result.push(hours.toString(), Translations.text(hours > 1 ? TranslationKey.hours : TranslationKey.hour));
        }
        if (minutes > 0) {
            result.push(minutes.toString(), Translations.text(minutes > 1 ? TranslationKey.minutes : TranslationKey.minute));
        }
        return result.join(" ");
    }

    public static formatFullDatePartShort(value: number): string {
        const dateParts: TSMap<DatePart, number> = this.dateParts(value);
        const days: number = dateParts.get(DatePart.days);
        const hours: number = dateParts.get(DatePart.hours);
        const minutes: number = dateParts.get(DatePart.minutes);
        const seconds: number = dateParts.get(DatePart.seconds);
        if (days > 0) {
            return days.toString() + Translations.text(TranslationKey.days[0].toUpperCase());
        }
        if (hours > 0) {
            return hours.toString() + Translations.text(TranslationKey.hours[0].toUpperCase());
        }
        if (minutes > 0) {
            return minutes.toString() + Translations.text(TranslationKey.minutes[0]);
        }
        if (seconds > 0) {
            return seconds.toString() + Translations.text(TranslationKey.seconds[0]);
        }
        return "";
    }

    public static isToday(someDate: moment.Moment): boolean {
        const today = moment();
        return someDate && someDate.day() === today.day() &&
            someDate.month() === today.month() &&
            someDate.year() === today.year();
    }

    public static isWeekend(): boolean {
        const day: number = (new Date()).getDay();
        return (day === 6) || (day === 0); // 6 = Saturday, 0 = Sunday
    }
}
