import {ActionCreatorDefinition, createAction} from "redux-ts";

export const DoFetchNewsType: string = "DoFetchNews";
export const SetSelectedNewsIdType: string = "SetSelectedNewsIdType";
export const SetNewsLoadingType: string = "SetNewsLoading";
export const SetNewsActiveTabType: string = "SetNewsActiveTab";

export interface DoFetchNewsPayload {

}

export interface SetSelectedNewsIdPayload {

    id: number | string;
}

export interface SetNewsLoadingPayload {

    loading: boolean;
}

export interface SetNewsActiveTabPayload {

    tab: string;
}

export const DoFetchNews: ActionCreatorDefinition<DoFetchNewsPayload, any> = createAction<DoFetchNewsPayload>(DoFetchNewsType);
export const SetSelectedNewsId: ActionCreatorDefinition<SetSelectedNewsIdPayload, any> = createAction<SetSelectedNewsIdPayload>(SetSelectedNewsIdType);
export const SetNewsLoading: ActionCreatorDefinition<SetNewsLoadingPayload, any> = createAction<SetNewsLoadingPayload>(SetNewsLoadingType)
export const SetNewsActiveTab: ActionCreatorDefinition<SetNewsActiveTabPayload, any> = createAction<SetNewsActiveTabPayload>(SetNewsActiveTabType);
