import {Deal} from "platform/protocol/trading/Deal";

interface ClosedTradesReduxState {

    ClosedTradesTable: PaginationDealsTable;
    ClosedTradeDetail?: Deal;
    OrdersHistoryTable: PaginationDealsTable;
    OrderDetail?: Deal;
}

interface PaginationDealsTable {

    deals?: Deal[];
    page?: number;
    pending?: boolean;
    hasMore?: boolean;
}

const DefaultPaginationDealsTable: PaginationDealsTable = {
    deals: [],
    page: 0,
    hasMore: false
};

export {
    ClosedTradesReduxState,
    PaginationDealsTable,
    DefaultPaginationDealsTable
};
