export enum DpkParam {

    Id = "id",
    Type = "type",
    JWT = "jwt",
    RIC = "RIC",
    SymbolId = "SymbolId",
    Direction = "Direction",
    StopLoss = "sl",
    TakeProfit = "tp",
    Method = "method",
    Currency = "currency",
    Amount = "amount",
    Ric = "ric",
    direction = "direction",
    Activity = "activity",
    Tab = "tab",
    VideoId = "vid",
    WalkThroughId = "wid",
    FollowedAccountId = "followedAccountId",
}
