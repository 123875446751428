class Confetti {

    public static Run = async (type: string): Promise<void> => {
        const jsConfetti: any = await import('js-confetti');
        const confetti = new jsConfetti.default();
        const confettiConfig: any = {
            confettiRadius: 6,
            confettiNumber: 500,
        };
        if (type === "heart") {
            confettiConfig.emojis = ['❤️'];
            confettiConfig.emojiSize = 16;
        }
        await confetti.addConfetti(confettiConfig);
    }
}

export {
    Confetti
};
