import {ActionCreatorDefinition, createAction} from "redux-ts";
import {PortfolioType} from "enum/PortfolioType";
import {BooleanPayload} from "src/core/redux/StoreActions";
import {PartnerAccount} from "protocol/partner/GetFollowedAccountsResponse";
import {TSMap} from "typescript-map";
import {RouteType} from "core/router/Routes";
import {SetAccountLogsPayload} from "core/redux/portfolio/PortfolioReduxActions";
import {PaginationDealsTablePayload} from "core/redux/trades/ClosedTradesReduxActions";
import {Deal} from "platform/protocol/trading/Deal";
import {PartnerAvailableFeatures} from "core/redux/partner/PartnerReduxState";
import {AccountAggregateData} from "protocol/account/GetAccountStateResponse";
import {Trade} from "core/redux/trades/TradesReduxState";
import {TradeType} from "protocol/trade/TradeType";
import {OpenPositionExtended} from "protocol/trade/OpenPositionExtended";
import {EntryOrderExtended} from "protocol/trade/EntryOrderExtended";
import {Quote} from "platform/protocol/trading/Quote";

export const SetPartnerAccountsEnabledType: string = "SetPartnerAccountsEnabled";
export const SetPartnerLoadingType: string = "SetPartnerLoading";
export const GetFollowedAccountsType: string = "GetFollowedAccounts";
export const SetFollowedAccountsType: string = "SetFollowedAccounts";
export const SetSelectedPartnerAccountIdType: string = "SetSelectedPartnerAccountId";
export const CreateFollowedAccountType: string = "CreateFollowedAccount";
export const DeleteFollowedAccountType: string = "DeleteFollowedAccount";
export const SetFollowedAccountType: string = "SetFollowedAccount";
export const RemoveFollowedAccountType: string = "RemoveFollowedAccount";
export const SetPartnerQuotesType: string = "SetPartnerQuotes";
export const SetPartnerPositionsOrdersType: string = "SetPartnerPositionsOrders";
export const SetPartnerAggregateDataType: string = "SetPartnerAggregateDataPromotions";
export const SetPartnerFinancialSummaryPromotionsType: string = "SetPartnerFinancialSummaryPromotions";
export const SetPartnerTabType: string = "SetPartnerTab";
export const RefreshFollowedAccountsType: string = "RefreshFollowedAccounts";
export const SetLastRefreshDateType: string = "SetLastRefreshDate";
export const SetPartnerServerErrorType: string = "SetPartnerServerError";
export const SetPartnerAccountLogsType: string = "SetPartnerAccountLogs";
export const SetPartnerPendingDataType: string = "SetPartnerPendingData";
export const SetPartnerAccountClosedTradesType: string = "SetPartnerAccountClosedTrades";
export const SetPartnerAccountOrderHistoryType: string = "SetPartnerAccountOrderHistory";
export const SetPartnerAccountCounterType: string = "SetPartnerAccountCounter";
export const GetExtendedPositionType: string = "GetExtendedPosition";
export const SetExtendedPositionType: string = "SetExtendedPosition";
export const SetExtendedEntryOrderType: string = "SetExtendedEntryOrder";

export interface SetPartnerLoadingPayload {

    loading: boolean;
}

export interface SetFollowedAccountsPayload {

    Accounts: TSMap<number, PartnerAccount>;
}

export interface CreateFollowedAccountPayload {

    // TODO Temp, need fetch from server in PartnerAccount.ts
    BrandId: number;
    Code: string;
}

export interface SetPartnerPortfolioTabPayload {

    portfolioType: PortfolioType;
    account: PartnerAccount;
}

export interface SetFollowedAccountPayload {

    account: PartnerAccount;
    action?: (route: RouteType, params: any) => void;
}

export interface FollowedAccountPayload {

    id: number;
    action?: (route: RouteType, params?: any) => void;
}

export interface SelectFollowedAccountPayload {

    account: PartnerAccount;
    action?: (route: RouteType, params?: any) => void;
}

export interface SetPartnerQuotesPayload {

    Quotes: TSMap<number, Quote>;
}

export interface SetPartnerPositionsOrdersPayload {

    Trades: Trade[];
    OpenPositionsMap: {[key: number]: Deal};
    EntryOrders: Deal[];
}

export interface SetPartnerAggregateDataPayload {

    AggregateData: AccountAggregateData;
}

export interface SetPartnerFinancialSummaryPromotionsPayload {

    AggregateData: AccountAggregateData;
    AvailableFeatures: PartnerAvailableFeatures;
}

export interface SetLastRefreshDatePayload {

    tabs: PortfolioType[];
    date: Date;
}

export interface SetPartnerServerErrorPayload {

    tabs: PortfolioType[];
    error: string;
}

export interface PartnerPortfolioCounterPayload {

    portfolioType: PortfolioType;
    count: number;
    filterCount?: number;
}

export interface GetExtendedPositionPayload {

    tradeType: TradeType;
    DealId: number;
    action?: () => void;
}

export interface SetExtendedPositionPayload {

    position: OpenPositionExtended;
}

export interface  SetExtendedEntryOrderPayload {

    order: EntryOrderExtended;
}

export const SetPartnerAccountsEnabled: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetPartnerAccountsEnabledType);
export const SetPartnerLoading: ActionCreatorDefinition<SetPartnerLoadingPayload, any> = createAction<SetPartnerLoadingPayload>(SetPartnerLoadingType);
export const GetFollowedAccounts: ActionCreatorDefinition<any, any> = createAction<any>(GetFollowedAccountsType);
export const SetFollowedAccounts: ActionCreatorDefinition<SetFollowedAccountsPayload, any> = createAction<SetFollowedAccountsPayload>(SetFollowedAccountsType);
export const SetSelectedPartnerAccountId: ActionCreatorDefinition<SelectFollowedAccountPayload, any> = createAction<SelectFollowedAccountPayload>(SetSelectedPartnerAccountIdType);
export const CreateFollowedAccount: ActionCreatorDefinition<CreateFollowedAccountPayload, any> = createAction<CreateFollowedAccountPayload>(CreateFollowedAccountType);
export const DeleteFollowedAccount: ActionCreatorDefinition<FollowedAccountPayload, any> = createAction<FollowedAccountPayload>(DeleteFollowedAccountType);
export const SetFollowedAccount: ActionCreatorDefinition<SetFollowedAccountPayload, any> = createAction<SetFollowedAccountPayload>(SetFollowedAccountType);
export const RemoveFollowedAccount: ActionCreatorDefinition<FollowedAccountPayload, any> = createAction<FollowedAccountPayload>(RemoveFollowedAccountType);
export const SetPartnerTab: ActionCreatorDefinition<SetPartnerPortfolioTabPayload, any> = createAction<SetPartnerPortfolioTabPayload>(SetPartnerTabType);
export const SetPartnerQuotes: ActionCreatorDefinition<SetPartnerQuotesPayload, any> = createAction<SetPartnerQuotesPayload>(SetPartnerQuotesType);
export const SetPartnerPositionsOrders: ActionCreatorDefinition<SetPartnerPositionsOrdersPayload, any> = createAction<SetPartnerPositionsOrdersPayload>(SetPartnerPositionsOrdersType);
export const SetPartnerAggregateData: ActionCreatorDefinition<SetPartnerAggregateDataPayload, any> = createAction<SetPartnerAggregateDataPayload>(SetPartnerAggregateDataType);
export const SetPartnerFinancialSummaryPromotions: ActionCreatorDefinition<SetPartnerFinancialSummaryPromotionsPayload, any> = createAction<SetPartnerFinancialSummaryPromotionsPayload>(SetPartnerFinancialSummaryPromotionsType);
export const RefreshFollowedAccounts: ActionCreatorDefinition<any, any> = createAction<any>(RefreshFollowedAccountsType);
export const SetLastRefreshDate: ActionCreatorDefinition<SetLastRefreshDatePayload, any> = createAction<SetLastRefreshDatePayload>(SetLastRefreshDateType);
export const SetPartnerServerError: ActionCreatorDefinition<SetPartnerServerErrorPayload, any> = createAction<SetPartnerServerErrorPayload>(SetPartnerServerErrorType);
export const SetPartnerAccountLogs: ActionCreatorDefinition<SetAccountLogsPayload, any> = createAction<SetAccountLogsPayload>(SetPartnerAccountLogsType);
export const SetPartnerPendingData: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetPartnerPendingDataType);
export const SetPartnerAccountClosedTrades: ActionCreatorDefinition<PaginationDealsTablePayload, any> = createAction<PaginationDealsTablePayload>(SetPartnerAccountClosedTradesType);
export const SetPartnerAccountOrderHistory: ActionCreatorDefinition<PaginationDealsTablePayload, any> = createAction<PaginationDealsTablePayload>(SetPartnerAccountOrderHistoryType);
export const SetPartnerAccountCounter: ActionCreatorDefinition<PartnerPortfolioCounterPayload, any> = createAction<PartnerPortfolioCounterPayload>(SetPartnerAccountCounterType);
export const GetExtendedPosition: ActionCreatorDefinition<GetExtendedPositionPayload, any> = createAction<GetExtendedPositionPayload>(GetExtendedPositionType);
export const SetExtendedPosition: ActionCreatorDefinition<SetExtendedPositionPayload, any> = createAction<SetExtendedPositionPayload>(SetExtendedPositionType);
export const SetExtendedEntryOrder: ActionCreatorDefinition<SetExtendedEntryOrderPayload, any> = createAction<SetExtendedEntryOrderPayload>(SetExtendedEntryOrderType);
